import React, { Component } from 'react'
import { Circle, Marker, GeoJSON, Polygon, Rectangle, Polyline } from 'react-leaflet'
import { connect } from 'react-redux'
import IPollution from '../../../model/IPollution'
import * as L from 'leaflet'
import {
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  sidePanelVerification,
} from '../../../redux/ripples.actions'
import {
  BlueCircleIcon,
  GreenCircleIcon,
  YellowCircleIcon,
  OrangeCircleIcon,
  RedCircleIcon,
  RedCircleSmalIcon,
  DropletDirtyIcon,
  DropletCleanIcon,
  BlueCircleSmallIcon,
  BlueArrowIcon,
} from './Icons'
import { LatLng } from 'leaflet'
import IObstacle from '../../../model/IObstacles'
import DateService from '../../../services/DateUtils'
import IPollutionSample from '../../../model/IPollutionSample'
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap'
import RotatedMarker from './RotatedMarker'
import IRipplesState from '../../../model/IRipplesState'
import CheckPosition from './CheckPosition'

export interface IAssetTrajectory {
  id: number
  imcId: number
  lat: number
  lon: number
  name: string
  timestamp: Date
}

export interface IPollutionTrajectory {
  alertID: number
  duration: number
  timestamp: number
  pollutionMarkers: string[]
}

export interface IPollutionTrajectoryWaypoints {
  latitude: number
  longitude: number
  timestamp: number
}

interface PropsType {
  locationSelected?: {
    latitude: any
    longitude: any
  }
  trajectoryLocation?: {
    latitude: any
    longitude: any
  }
  pollutionMarkers?: IPollution[]
  pollutionOpen: IPollution[]
  obstaclePolygons?: IObstacle[]
  obstacleLocationSelected: {
    latitude: any
    longitude: any
  }[]
  trajectoryLocationOpen: boolean
  assetTrajectory: IAssetTrajectory[]
  pollutionSamples?: IPollutionSample[]
  pollutionTrajectories: IPollutionTrajectory[]
  isPollutionTrajectoryModalOpen: boolean
  displayPollutionTrajectory: boolean
  isDarkMode: boolean

  addCircle: (pollution: IPollution) => void
  removeCircle: (pollution: IPollution) => void
  setObstacle: (obstacle: IObstacle) => void
  togglePollutionTrajectoriesModal: () => void
  fetchPollutionTrajectoryWaypoints: (alertID: number) => any
  setSidePanelTitle: (title: string) => void
  setSidePanelContent: (content: any) => void
  setSidePanelVisibility: (v: boolean) => void
  sidePanelVerification: () => void
}

interface StateType {
  trajectoryWaypoints: IPollutionTrajectoryWaypoints[]
}

class Pollution extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props)

    this.state = {
      trajectoryWaypoints: [],
    }

    this.onObstacleClick = this.onObstacleClick.bind(this)
    this.handleFetchTrajectory = this.handleFetchTrajectory.bind(this)
  }

  public buildPollutionMarkers() {
    if (this.props.pollutionMarkers) {
      return this.props.pollutionMarkers.map((p, index) => {
        const point = new L.LatLng(p.latitude, p.longitude)
        let color = ''
        let icon: L.Icon
        switch (p.status) {
          case 'Synched':
            color = 'yellow'
            icon = new YellowCircleIcon()
            break
          case 'Exec':
            color = 'orange'
            icon = new OrangeCircleIcon()
            break
          case 'Done':
            color = 'green'
            icon = new GreenCircleIcon()
            break
          default:
            color = 'red'
            icon = new RedCircleIcon()
            break
        }
        if (this.props.pollutionOpen.includes(p)) {
          return (
            <Circle
              key={'pollutionCircle_' + index}
              center={[point.lat, point.lng]}
              fillColor={color}
              color={color}
              radius={p.radius}
              onclick={() => this.props.removeCircle(p)}
            />
          )
        } else {
          return (
            <Marker
              key={'pollutionMarker_' + index}
              position={[point.lat, point.lng]}
              icon={icon}
              onClick={() => this.props.addCircle(p)}
            />
          )
        }
      })
    }
  }

  public buildSelectedLocation() {
    if (this.props.locationSelected) {
      return (
        <Marker
          position={[this.props.locationSelected.latitude, this.props.locationSelected.longitude]}
          icon={new BlueCircleIcon()}
        />
      )
    }
  }

  public buildTrajectoryLocation() {
    if (this.props.trajectoryLocation) {
      return (
        <Marker
          position={[this.props.trajectoryLocation.latitude, this.props.trajectoryLocation.longitude]}
          icon={new BlueCircleIcon()}
        />
      )
    }
  }

  public buildAssetTrajectoryWP() {
    if (this.props.assetTrajectory.length > 0) {
      return this.props.assetTrajectory.map((pos, index) => {
        return (
          <Marker
            key={'assetTrajectory_' + index}
            position={[pos.lat, pos.lon]}
            icon={new RedCircleSmalIcon()}
            title={DateService.formatDate(pos.timestamp)}
          />
        )
      })
    }
  }

  public buildAssetTrajectory() {
    if (this.props.assetTrajectory.length > 0) {
      const polyline: LatLng[] = []
      this.props.assetTrajectory.forEach((pos) => {
        polyline.push(new LatLng(pos.lat, pos.lon))
      })
      return <Polyline positions={polyline} color="red" weight={1} dashArray="3" />
    }
  }

  public buildObstaclesPolygons() {
    if (this.props.obstaclePolygons) {
      return this.props.obstaclePolygons.map((obstacle, index) => {
        const positions: L.LatLng[] = []
        obstacle.positions.forEach((pos) => {
          const obstaclePosition: L.LatLng = new LatLng(pos[0], pos[1])
          positions.push(obstaclePosition)
        })

        return (
          <Polygon
            key={'obstaclePolygon_' + index}
            positions={positions}
            color="black"
            onClick={(e: any) => this.onObstacleClick(e, obstacle)}
          />
        )
      })
    }
  }

  public onObstacleClick(evt: any, obstacle: IObstacle) {
    evt.originalEvent.view.L.DomEvent.stopPropagation(evt)
    this.props.setSidePanelTitle(obstacle.description)
    this.props.setSidePanelVisibility(true)
    // Move sidepanel if sidebar is open
    this.props.sidePanelVerification()
    this.props.setObstacle(obstacle)
  }

  public async handleFetchTrajectory(alertID: number) {
    const wps: IPollutionTrajectoryWaypoints[] = await this.props.fetchPollutionTrajectoryWaypoints(alertID)
    this.setState({ trajectoryWaypoints: wps })
    this.props.togglePollutionTrajectoriesModal()
  }

  public drawObstacleLocation() {
    if (this.props.obstacleLocationSelected) {
      const positions: L.LatLng[] = []
      this.props.obstacleLocationSelected.forEach((obstacle) => {
        const pos: L.LatLng = new LatLng(obstacle.latitude, obstacle.longitude)
        positions.push(pos)
      })

      return <Polygon positions={positions} color="red" />
    }
  }

  public buildLimits() {
    const outer: any = [
      [40.59571, -8.86626],
      [40.65655, -8.74172],
    ]
    if (this.props.trajectoryLocationOpen) {
      return <Rectangle bounds={outer} pathOptions={{ color: 'cyan' }} />
    }
  }

  public buildPollutionSamples() {
    if (this.props.pollutionSamples) {
      return this.props.pollutionSamples.map((sample) => {
        if (sample.status.includes('CLEAN')) {
          return (
            <Marker
              key={'pollutionSample_' + sample.id}
              position={[sample.latitude, sample.longitude]}
              icon={new DropletCleanIcon()}
              title={'Clean sample \n' + DateService.formatDate(sample.timestamp)}
            />
          )
        } else {
          return (
            <Marker
              key={'pollutionSample_' + sample.id}
              position={[sample.latitude, sample.longitude]}
              icon={new DropletDirtyIcon()}
              title={'Dirty sample \n' + DateService.formatDate(sample.timestamp)}
            />
          )
        }
      })
    }
  }

  public buildPollutionTrajectories() {
    if (this.props.isPollutionTrajectoryModalOpen) {
      return (
        <Modal
          id={
            this.props.isDarkMode ? 'modal-darkmode pollutionTrajectoryModal' : 'modal-light pollutionTrajectoryModal'
          }
          isOpen={this.props.isPollutionTrajectoryModalOpen}
          toggle={this.props.togglePollutionTrajectoriesModal}
        >
          <ModalHeader toggle={this.props.togglePollutionTrajectoriesModal}>Pollution Trajectories</ModalHeader>
          <ModalBody>
            <Table id="pollutionTrajectories-table" responsive={true} striped={true}>
              <thead>
                <tr>
                  <th>AlertID</th>
                  <th>Timestamp</th>
                  <th>Duration</th>
                  <th>Pollution markers</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {this.props.pollutionTrajectories.map((traj, index) => {
                  return (
                    <tr key={index}>
                      <td>{traj.alertID}</td>
                      <td>{DateService.timestampSecToReadableDate(traj.timestamp)}</td>
                      <td>{DateService.secondsToTime(traj.duration)}</td>
                      <td>{traj.pollutionMarkers}</td>
                      <td>
                        <i
                          className="fas fa-eye"
                          title="Diplay trajectory"
                          onClick={(event) => this.handleFetchTrajectory(traj.alertID)}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </ModalBody>
        </Modal>
      )
    }
  }

  public buildPollutionTrajectoryWP() {
    if (this.props.displayPollutionTrajectory) {
      return this.state.trajectoryWaypoints.map((wp, index) => {
        return (
          <Marker
            key={'trajectory_WP_' + index}
            position={[wp.latitude, wp.longitude]}
            title={'Estimated position #' + index + '\n' + DateService.timestampSecToReadableDate(wp.timestamp)}
            icon={new BlueCircleSmallIcon()}
          />
        )
      })
    }
  }

  public buildPollutionTrajectory() {
    if (this.props.displayPollutionTrajectory) {
      const polyline: LatLng[] = []
      this.state.trajectoryWaypoints.forEach((pos) => {
        polyline.push(new LatLng(pos.latitude, pos.longitude))
      })
      return <Polyline positions={polyline} color="blue" weight={1} dashArray="3" />
    }
  }

  public buildPollutionTrajectoryArrows() {
    if (this.props.displayPollutionTrajectory) {
      const arrows: LatLng[] = []
      const arrowHeading: number[] = []
      let i = 0
      this.state.trajectoryWaypoints.forEach((pos, index) => {
        if (i === 10) {
          if (this.state.trajectoryWaypoints[index] && this.state.trajectoryWaypoints[index + 1]) {
            // middle point
            const lat =
              (this.state.trajectoryWaypoints[index].latitude + this.state.trajectoryWaypoints[index + 1].latitude) / 2
            const lng =
              (this.state.trajectoryWaypoints[index].longitude + this.state.trajectoryWaypoints[index + 1].longitude) /
              2

            // point heading
            const p1 = this.state.trajectoryWaypoints[index]
            const center = this.state.trajectoryWaypoints[index + 1]
            const heading = (Math.atan2(center.longitude - p1.longitude, center.latitude - p1.latitude) * 180) / Math.PI

            arrows.push(new LatLng(lat, lng))
            arrowHeading.push(heading - 90)
          }
          i = 0
        }
        i++
      })

      return arrows.map((a, index) => {
        return (
          <RotatedMarker
            key={'trajectory_heading_' + index}
            position={{ lat: a.lat, lng: a.lng }}
            rotationAngle={arrowHeading[index]}
            icon={new BlueArrowIcon()}
            title={'Estimated trajectory heading'}
          />
        )
      })
    }
  }

  public buildAveiroArea() {
    const geojsonFeature: any = {
      type: 'FeatureCollection',
      name: 'aveiro',
      crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
      features: [
        {
          type: 'Feature',
          properties: { id: 0, INDEXKEY: null },
          geometry: {
            type: 'Polygon',
            coordinates: [new CheckPosition().aveiroArea],
          },
        },
      ],
    }

    return (
      <GeoJSON
        data={geojsonFeature}
        style={() => {
          return {
            color: '#f8971b',
            weight: 1,
            fillOpacity: 0.2,
          }
        }}
      />
    )
  }

  public render() {
    return (
      <>
        {this.buildAveiroArea()}
        {this.buildLimits()}
        {this.buildPollutionMarkers()}
        {this.buildSelectedLocation()}
        {this.buildObstaclesPolygons()}
        {this.drawObstacleLocation()}
        {this.buildTrajectoryLocation()}
        {this.buildAssetTrajectoryWP()}
        {this.buildAssetTrajectory()}
        {this.buildPollutionSamples()}
        {this.buildPollutionTrajectories()}
        {this.buildPollutionTrajectoryWP()}
        {this.buildPollutionTrajectory()}
        {this.buildPollutionTrajectoryArrows()}
      </>
    )
  }
}

function mapStateToProps(state: IRipplesState) {
  return {
    isDarkMode: state.isDarkMode,
  }
}

const actionCreators = {
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  sidePanelVerification,
}

export default connect(mapStateToProps, actionCreators)(Pollution)
