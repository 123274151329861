import React, { Component } from 'react'
import {
  Circle,
  GeoJSON,
  LayerGroup,
  LayersControl,
  Map as LeafletMap,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  WMSTileLayer,
} from 'react-leaflet'
import 'react-leaflet-fullscreen-control'
import { connect } from 'react-redux'
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
} from 'reactstrap'
import IAisShip, { IShipLocation } from '../../../model/IAisShip'
import IAnnotation, { NewAnnotation } from '../../../model/IAnnotations'
import IAsset, { isSameAsset } from '../../../model/IAsset'
import IAuthState, { IUserLocation, isL3, isL0, isL1, isL2, hasL3Permissions, isL4 } from '../../../model/IAuthState'
import IGeoLayer from '../../../model/IGeoLayer'
import ILatLng from '../../../model/ILatLng'
import IMyMap, { IMapSettings } from '../../../model/IMyMap'
import IPlan from '../../../model/IPlan'
import IPositionAtTime from '../../../model/IPositionAtTime'
import IProfile from '../../../model/IProfile'
import IRipplesState from '../../../model/IRipplesState'
import { ToolSelected } from '../../../model/ToolSelected'
import { WeatherParam } from '../../../model/WeatherParam'
import {
  addMeasurePoint,
  removeMeasurePoint,
  addWpToPlan,
  clearMeasure,
  selectVehicleLastState,
  selectPlanPosition,
  setCcus,
  setEditVehicle,
  setMapOverlayInfo,
  setSpots,
  setVehicles,
  setSelectedWaypointIdx,
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  sidePanelVerification,
  setToolClickLocation,
  setToolSelected,
  toggleSliderChange,
  toggleVehicleModal,
  updateVehicle,
  updateWpLocation,
  toggleGps,
  setWeatherParam,
  selectContact,
  setEditingMapElement,
  selectMapElement,
} from '../../../redux/ripples.actions'
import DateService from '../../../services/DateUtils'
import LogbookService from '../../../services/LogbookUtils'
import MapUtils from '../../../services/MapUtils'
import PositionService from '../../../services/PositionUtils'
import SoiService from '../../../services/SoiUtils'
import WeatherService, { IWeather, WeatherData, WeatherSource } from '../../../services/WeatherUtils'
import '../styles/Ripples.css'
import AISCanvas from './AISCanvas'
import AISShip from './AISShip'
import ClientLocation from './ClientLocation'
import {
  BlueCircleIcon,
  GeoJsonMarker,
  PCIcon,
  SpotIcon,
  MyLocationIcon,
  KsatVesselCorrelated,
  KsatVesselHigh,
  KsatVesselMedium,
  KsatVesselLow,
  KsatVesselAIS,
} from './Icons'
import ksatLegend from '../../../assets/ksat/ksatLegend.png'
import SimpleAsset from './SimpleAsset'
import Vehicle from './Vehicle'
import VerticalProfile from './VerticalProfile'
import WeatherLinePlot from './WeatherLinePlot'
import PlanManager from './PlanManager'
import L from 'leaflet'
import IPollution from '../../../model/IPollution'
import PollutionService from '../../../services/PollutionUtils'
import Pollution, { IAssetTrajectory, IPollutionTrajectory, IPollutionTrajectoryWaypoints } from './Pollution'
import IObstacle from '../../../model/IObstacles'
import IAssetState from '../../../model/IAssetState'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import IPollutionSample from '../../../model/IPollutionSample'
import IContact from '../../../model/IContact'
import Contact from './Contact'
import IMapElement from '../../../model/IMapElement'
import MapElement from './MapElement'
import Sidebar from './Sidebar'
import FollowAsset from './FollowAsset'
import IAircraft from '../../../model/IAircraft'
import Aircraft from './Aircraft'
import SettingsService from '../../../services/SettingsUtils'
import colabLogo from '../../../assets/colabLogo.png'
import { IServicesDefinition } from '../../../model/IService'
import { isMyMapsServiceAvailable } from '../../../services/ServicesUtils'

const { NotificationManager } = require('react-notifications')
const { CoordinatesControl } = require('react-leaflet-coordinates')

const CanvasLayer = require('react-leaflet-canvas-layer')
const { BaseLayer, Overlay } = LayersControl

export interface LayerInfo {
  name: string
  group: string
  legendStyle?: string
  units?: string
  lowerValue?: number
  higherValue?: number
  isLog?: boolean
}

interface PropsType {
  auth: IAuthState
  aisLocations: IShipLocation[]
  vehicles: IAsset[]
  spots: IAsset[]
  ccus: IAsset[]
  aisShips: IAisShip[]
  profiles: IProfile[]
  plans: IPlan[]
  selectedPlan: IPlan
  selectedWaypointIdx: number
  toolSelected: ToolSelected
  isGpsActive: boolean
  myMaps: IMyMap[]
  geoLayers?: IGeoLayer[] | null
  geoServerAddr?: string
  measurePath: ILatLng[]
  annotations: IAnnotation[]
  usersLocations: IUserLocation[]
  isVehicleModalOpen: boolean
  editVehicle?: IAsset
  sliderValue: number
  hasSliderChanged: boolean
  weatherParam: WeatherParam | null
  toolClickLocation: ILatLng | null
  pollution: IPollution[]
  obstacle: IObstacle[]
  pollutionSample: IPollutionSample[]
  contacts: IContact[]
  aircrafts: IAircraft[]
  vehicleSelectedLastState: IAssetState | null
  vehicleSelected: string
  planSelectedPosition: ILatLng | null
  contactSelected: IContact | null
  mapElements: IMapElement[]
  layersSettings: any
  sidebar: Sidebar
  isDarkMode: boolean
  preferenceSymbolType: string
  preferenceCoordsDisplayFormat: string
  servicesAvailable: IServicesDefinition[]
  addLayer: (_: LayerInfo) => void
  setSelectedWaypointIdx: (_: number) => void
  updateWpLocation: (_: ILatLng) => void
  addWpToPlan: (_: IPositionAtTime) => void
  selectVehicleLastState: (_: IAssetState | null) => void
  selectPlanPosition: (_: ILatLng | null) => void
  setVehicles: (_: IAsset[]) => void
  setSpots: (_: IAsset[]) => void
  setCcus: (_: IAsset[]) => void
  setSidePanelVisibility: (_: boolean) => void
  sidePanelVerification: () => void
  setSidePanelTitle: (_: string) => void
  setSidePanelContent: (_: any) => void
  addMeasurePoint: (_: ILatLng) => void
  removeMeasurePoint: (_: number) => void
  clearMeasure: () => void
  toggleVehicleModal: () => void
  setEditVehicle: (v: IAsset | undefined) => void
  updateVehicle: (v: IAsset) => void
  onSettingsClick: () => void
  toggleSliderChange: () => void
  setMapOverlayInfo: (m: string) => void
  setToolClickLocation: (l: ILatLng | null) => void
  updateAssets: (s: IAsset, d: string[]) => void
  setPollutionMarkers: () => void
  setObstacles: () => void
  setContactMarkers: () => void
  setToolSelected: (_: ToolSelected) => void
  toggleGps: () => void
  setWeatherParam: (p: WeatherParam | null) => void
  selectContact: (_: IContact | null) => void
  selectMapElement: (_: IMapElement | null) => void
  setEditingMapElement: (_: boolean) => void
}

interface StateType {
  settings: IMapSettings
  isToDrawAISPolygons: boolean
  perpLinesSize: number
  currentTime: number
  isAISLayerActive: boolean
  isAircraftLayerActive: boolean
  isVehiclesLayerActive: boolean
  activeLegend: string[]
  newAnnotationContent: string
  clickLocationWeather: IWeather[]
  assetSelected: IAsset | undefined
  isLayerControlCollapsed: boolean
  isFollowAssetLayerActive: boolean
  isPollutionLayerActive: boolean
  pollutionEnable: boolean
  pollutionConfig: boolean
  editPollutionConfig: string
  pollutionDescription: string
  pollutionRadius: number
  pollutionLocation?: {
    latitude: any
    longitude: any
  }
  pollutionOpen: IPollution[]
  pollutionSyncSelector: boolean
  pollutionTrajectoryLocationOpen: boolean
  pollutionTrajectoryLocation?: {
    latitude: any
    longitude: any
  }
  pollutionTrajectoryTimestamp: Date
  editPollutionMarker?: IPollution
  editObstacle?: IObstacle
  pollutionDescriptionUpdate: string
  pollutionRadiusUpdate: number
  pollutionLatitudeUpdate: number
  pollutionLongitudeUpdate: number
  isPollutionModalOpen: boolean
  isObstacleModalOpen: boolean
  obstacleEnable: boolean
  obstacleDescription: string
  obstacleLocation: {
    latitude: any
    longitude: any
  }[]
  pollutionFollowAssetSinceTimestamp: Date
  isFollowAssetOpen: boolean
  assetTrajectory: IAssetTrajectory[]
  pollutionTrajectories: IPollutionTrajectory[]
  isPollutionTrajectoryModalOpen: boolean
  isPollutionTrajectorySelected: boolean
  currentZoom: number
  isContactLayerActive: boolean
  isMapElementLayerActive: boolean
  highlightedShip: string
  isMyLocationActive: boolean
  isSelectedPositionEnable: boolean
  selectedPosition?: ILatLng
  threddServerUrl: string
  displayLegend?: string
  profilesLayers: string[]
}

class RipplesMap extends Component<PropsType, StateType> {
  public upgradedOptions: any
  public initZoom = 10
  public oneSecondTimer = 0
  public followAssetTimer = 0
  private map!: LeafletMap
  private positionService = new PositionService()
  private blueCircleIcon = new BlueCircleIcon()
  private logBookService = new LogbookService()
  private soiService = new SoiService()
  private weatherService = new WeatherService()
  private newAnnotationPopupRef: React.RefObject<Popup> = React.createRef()
  private vehicleChangedSettings: Map<string, string> = new Map()
  private lastWaveMapTime: string = MapUtils.resetMapTime(3)
  private lastWindMapTime: string = MapUtils.resetMapTime(6)
  private lastMohidMapTime: string = MapUtils.resetMapTime(6)
  private pollutionService: PollutionService = new PollutionService()
  private settingsService: SettingsService = new SettingsService()
  private myLocationIcon = new MyLocationIcon()
  private ais: IAisShip[] = []

  constructor(props: PropsType) {
    super(props)

    this.state = {
      settings: {
        lat: MapUtils.initCoords.lat,
        lng: MapUtils.initCoords.lng,
        zoom: MapUtils.initZoom,
      },
      isToDrawAISPolygons: false,
      perpLinesSize: 10,
      currentTime: Date.now(),
      isAISLayerActive: true,
      isAircraftLayerActive: false,
      isVehiclesLayerActive: true,
      activeLegend: [],
      newAnnotationContent: '',
      clickLocationWeather: [],
      assetSelected: undefined,
      isLayerControlCollapsed: false,
      isFollowAssetLayerActive: false,
      isPollutionLayerActive: false,
      pollutionEnable: false,
      pollutionConfig: false,
      editPollutionConfig: '',
      pollutionDescription: '',
      pollutionRadius: 20,
      pollutionOpen: [],
      pollutionSyncSelector: false,
      pollutionTrajectoryLocationOpen: false,
      pollutionTrajectoryTimestamp: new Date(),
      editPollutionMarker: undefined,
      editObstacle: undefined,
      pollutionDescriptionUpdate: '',
      pollutionRadiusUpdate: 20,
      pollutionLatitudeUpdate: 0,
      pollutionLongitudeUpdate: 0,
      isPollutionModalOpen: false,
      isObstacleModalOpen: false,
      obstacleEnable: false,
      obstacleDescription: '',
      obstacleLocation: [],
      pollutionFollowAssetSinceTimestamp: new Date(),
      isFollowAssetOpen: false,
      assetTrajectory: [],
      pollutionTrajectories: [],
      isPollutionTrajectoryModalOpen: false,
      isPollutionTrajectorySelected: false,
      currentZoom: MapUtils.initZoom,
      isContactLayerActive: false,
      isMapElementLayerActive: false,
      highlightedShip: '',
      isMyLocationActive: false,
      isSelectedPositionEnable: false,
      threddServerUrl: '',
      displayLegend: undefined,
      profilesLayers: [],
    }
    this.handleMapClick = this.handleMapClick.bind(this)
    this.handleZoom = this.handleZoom.bind(this)
    this.handleMove = this.handleMove.bind(this)
    this.drawCanvas = this.drawCanvas.bind(this)
    this.toggleDrawAisLocations = this.toggleDrawAisLocations.bind(this)
    this.onMapAnnotationClick = this.onMapAnnotationClick.bind(this)
    this.onLocationClick = this.onLocationClick.bind(this)
    this.onEditVehicle = this.onEditVehicle.bind(this)
    this.setAssetSelected = this.setAssetSelected.bind(this)
    this.buildPollutionDialog = this.buildPollutionDialog.bind(this)
    this.handleChangePollutionDescription = this.handleChangePollutionDescription.bind(this)
    this.handleChangePollutionDescriptionUpdate = this.handleChangePollutionDescriptionUpdate.bind(this)
    this.handleChangePollutionRadius = this.handleChangePollutionRadius.bind(this)
    this.handleChangePollutionRadiusUpdate = this.handleChangePollutionRadiusUpdate.bind(this)
    this.handleChangePollutionLatitudeUpdate = this.handleChangePollutionLatitudeUpdate.bind(this)
    this.handleChangePollutionLongitudeUpdate = this.handleChangePollutionLongitudeUpdate.bind(this)
    this.handleChangeObstacleDescription = this.handleChangeObstacleDescription.bind(this)
    this.handleChangePollutionConfig = this.handleChangePollutionConfig.bind(this)
    this.handlePollutionEdit = this.handlePollutionEdit.bind(this)
    this.handleAddPollutionCircle = this.handleAddPollutionCircle.bind(this)
    this.handleRemovePollutionCircle = this.handleRemovePollutionCircle.bind(this)
    this.handleDeletePollution = this.handleDeletePollution.bind(this)
    this.handleDeleteObstacle = this.handleDeleteObstacle.bind(this)
    this.handleSelectedObstacle = this.handleSelectedObstacle.bind(this)
    this.togglePollutionModal = this.togglePollutionModal.bind(this)
    this.toggleObstacleModal = this.toggleObstacleModal.bind(this)
    this.onMeasureToggle = this.onMeasureToggle.bind(this)
    this.onGpsClick = this.onGpsClick.bind(this)
    this.onMyLocationToggle = this.onMyLocationToggle.bind(this)
    this.onMyLocationClick = this.onMyLocationClick.bind(this)
    this.displayMyLocationInfo = this.displayMyLocationInfo.bind(this)
    this.onAnnotationToggle = this.onAnnotationToggle.bind(this)
    this.onToolpickToogle = this.onToolpickToogle.bind(this)
    this.buildWeatherSelector = this.buildWeatherSelector.bind(this)
    this.handleListTrajectories = this.handleListTrajectories.bind(this)
    this.togglePollutionTrajectoriesModal = this.togglePollutionTrajectoriesModal.bind(this)
    this.fetchPollutionTrajectoryWaypoints = this.fetchPollutionTrajectoryWaypoints.bind(this)
    this.handleFollowAsset = this.handleFollowAsset.bind(this)
    this.handleUnfollowAsset = this.handleUnfollowAsset.bind(this)
    this.handleAssetTrajectoryDatepicker = this.handleAssetTrajectoryDatepicker.bind(this)
    this.handleSelectPosition = this.handleSelectPosition.bind(this)
    this.buildLegend = this.buildLegend.bind(this)

    if (this.props.auth.authenticated && !isL4(this.props.auth)) {
      this.fetchMapSettings()
    }
  }

  public async componentDidMount() {
    if (!this.oneSecondTimer) {
      this.oneSecondTimer = window.setInterval(() => {
        this.updateCopernicusMaps()
        this.setState({ currentTime: Date.now() })
      }, 2000)
    }
    this.map = this.refs.map as LeafletMap

    // Ramp
    const pollutionServer = await this.pollutionService.fetchPollutionExternalServer()
    if (
      this.props.auth.authenticated &&
      (isL0(this.props.auth) || isL1(this.props.auth) || isL2(this.props.auth) || isL3(this.props.auth))
    ) {
      this.setState({ editPollutionConfig: pollutionServer })
    } else {
      this.setState({ editPollutionConfig: '' })
    }

    // Thredds server url
    const response = await this.settingsService.fetchThreddServerUrl()
    if (response.status === 'Success') {
      this.setState({ threddServerUrl: response.message })
    }

    // organize layers
    await sleep(10)
    const x = document.getElementsByClassName('leaflet-control-layers-selector')
    for (const item of x) {
      const parentElem = item.parentElement
      if (parentElem && parentElem.lastChild) {
        if (
          parentElem.lastChild.textContent === ' Profiles Data' ||
          parentElem.lastChild.textContent === ' Annotations' ||
          parentElem.lastChild.textContent === ' Pollution Data'
        ) {
          parentElem.style.borderTop = '1px solid #ddd'
        }
        if (parentElem.lastChild.textContent === ' Measure track' || parentElem.lastChild.textContent === ' CCUS') {
          parentElem.style.borderBottom = '1px solid #ddd'
          // parentElem.style.paddingBottom = '5px'
        }
      }
    }
    this.props.sidebar.buildSidebar(this.props.auth)

    function sleep(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    }

    if (this.map) {
      const mapBounds = this.map.leafletElement.getBounds()
      this.ais = this.props.aisShips.filter((ais) => mapBounds.contains([ais.latitude, ais.longitude]))
    }
  }

  public updateCopernicusMaps() {
    if (this.props.hasSliderChanged) {
      this.lastWaveMapTime = MapUtils.buildRequestTime(this.props.sliderValue, 3)

      // Verify if it is in the same day
      if (
        MapUtils.buildRequestTime(this.props.sliderValue, 6).includes(new Date(Date.now()).toISOString().split('T')[0])
      ) {
        this.lastMohidMapTime = MapUtils.buildRequestTime(this.props.sliderValue, 6)
      } else {
        this.lastMohidMapTime = MapUtils.resetMapTime(6)
      }

      if (this.props.sliderValue < 0) {
        this.lastWindMapTime = MapUtils.buildRequestTime(this.props.sliderValue, 6)
      } else {
        this.lastWindMapTime = MapUtils.resetMapTime(6)
      }
      this.props.toggleSliderChange()
    }
  }

  public async componentDidUpdate(prevProps: PropsType) {
    if (this.props.vehicleSelectedLastState !== null) {
      const newSettings: IMapSettings = {
        lat: this.props.vehicleSelectedLastState.latitude,
        lng: this.props.vehicleSelectedLastState.longitude,
        zoom: 18,
      }
      this.map.leafletElement.setView([newSettings.lat, newSettings.lng], newSettings.zoom)
    }

    if (this.props.planSelectedPosition !== null) {
      const newSettings: IMapSettings = {
        lat: this.props.planSelectedPosition.latitude,
        lng: this.props.planSelectedPosition.longitude,
        zoom: 18,
      }
      this.map.leafletElement.setView([newSettings.lat, newSettings.lng], newSettings.zoom)
    }
    if (this.map) {
      const mapBounds = this.map.leafletElement.getBounds()
      this.ais = this.props.aisShips.filter((ais) => mapBounds.contains([ais.latitude, ais.longitude]))
    }

    // VerticalProfileData: split by system
    if (prevProps.profiles !== this.props.profiles) {
      const profilesUniqueSystems: string[] = []
      this.props.profiles.forEach((p) => {
        if (!profilesUniqueSystems.includes(p.system)) {
          profilesUniqueSystems.push(p.system)
        }
      })
      this.setState({ profilesLayers: profilesUniqueSystems.sort() })
    }
  }

  public componentWillUnmount() {
    clearInterval(this.oneSecondTimer)
    clearInterval(this.followAssetTimer)
    this.props.setSidePanelVisibility(false)
  }

  /**
   * Move waypoint if a plan and a waypoint are selected
   * @param e
   */
  public handleMapClick(e: any) {
    const clickLocation = { latitude: e.latlng.lat, longitude: e.latlng.lng }
    switch (this.props.toolSelected) {
      case ToolSelected.MEASURE: {
        if (e.originalEvent.ctrlKey) {
          if (e.originalEvent.srcElement.getAttribute('alt') !== null) {
            const measureMarkerIndex = e.originalEvent.srcElement.getAttribute('alt').split('_').pop()
            this.onMapMeasureClickRemove(measureMarkerIndex)
          }
        } else {
          this.onMapMeasureClick(clickLocation)
        }
        break
      }
      case ToolSelected.ANNOTATION: {
        this.onMapAnnotationClick(clickLocation)
        break
      }
      case ToolSelected.TOOLPICK: {
        this.onMapToolpickClick(clickLocation)
        break
      }
      case ToolSelected.MYLOCATION: {
        if (e.originalEvent.srcElement.nodeName === 'CANVAS') {
          this.onMyLocationClick(clickLocation)
        }
        break
      }
      default:
        this.toggleHighlight()
        this.props.setSidePanelVisibility(false)
        this.props.selectContact(null)
        this.props.selectMapElement(null)
        break
    }

    if (e.originalEvent.srcElement.className && typeof e.originalEvent.srcElement.className.includes !== 'undefined') {
      this.setAssetSelected(undefined)
    } else {
      // popup pollution data
      if (e.originalEvent.srcElement.nodeName !== 'BUTTON' && e.originalEvent.srcElement.nodeName !== 'INPUT') {
        if (this.state.pollutionEnable) {
          this.setState({ pollutionLocation: clickLocation })
        }
        if (this.state.obstacleEnable) {
          this.setState({ obstacleLocation: [...this.state.obstacleLocation, clickLocation] })
        }
        if (this.state.editObstacle) {
          this.setState({ editObstacle: undefined })
        }
        if (this.state.pollutionTrajectoryLocationOpen) {
          this.setState({ pollutionTrajectoryLocation: clickLocation })
        }
      }
    }

    // set contact location
    if (this.state.isContactLayerActive && this.state.isSelectedPositionEnable) {
      if (e.originalEvent.srcElement.nodeName === 'CANVAS') {
        this.setState({ selectedPosition: clickLocation })
      }
    }

    // this.crossingPlans = []
  }

  public getGeoJSONSidePanelProperties(properties: any) {
    let obj = {}
    if (properties.lat) {
      obj = Object.assign({}, obj, { lat: properties.lat })
    }
    if (properties.lon) {
      obj = Object.assign({}, obj, { lng: properties.lon })
    }
    if (properties.CATEGORIA) {
      obj = Object.assign({}, obj, { category: properties.CATEGORIA })
    }
    return obj
  }

  public buildMyMaps() {
    return this.props.myMaps.map((map) => {
      return (
        <Overlay key={`Overlay_${map.name}`} checked={false} name={map.name}>
          <LayerGroup>
            <GeoJSON
              data={map.data}
              style={(feature: any) => {
                return {
                  color: feature.properties.stroke,
                  weight: feature.properties['stroke-width'],
                }
              }}
              onEachFeature={(feature, layer) => {
                if (feature.properties && feature.properties.name) {
                  layer.on('click', (evt: any) => {
                    if (this.props.toolSelected !== ToolSelected.MEASURE) {
                      evt.originalEvent.view.L.DomEvent.stopPropagation(evt)
                      this.props.setSidePanelTitle(feature.properties.name)
                      this.props.setSidePanelContent(this.getGeoJSONSidePanelProperties(feature.properties))
                      this.props.setSidePanelVisibility(true)
                      // Move sidepanel if sidebar is open
                      this.props.sidePanelVerification()
                      this.props.setEditVehicle(undefined)
                    }
                  })
                }
              }}
              pointToLayer={(feature, latlng) => {
                const description: string = feature.properties.description
                // KSAT parser
                if (description) {
                  if (description.length > 2000) {
                    return L.marker(latlng, { icon: new KsatVesselCorrelated() })
                  } else if (description.includes('HIGH')) {
                    return L.marker(latlng, { icon: new KsatVesselHigh() })
                  } else if (description.includes('MEDIUM')) {
                    return L.marker(latlng, { icon: new KsatVesselMedium() })
                  } else if (description.includes('LOW')) {
                    return L.marker(latlng, { icon: new KsatVesselLow() })
                  } else if (description.includes('AIS')) {
                    return L.marker(latlng, { icon: new KsatVesselAIS() })
                  } else if (description.includes('track_green')) {
                    return L.marker(latlng, { icon: new GeoJsonMarker() })
                  }
                }
                return L.marker(latlng, { icon: new GeoJsonMarker() })
              }}
            />
          </LayerGroup>
          {this.props.addLayer({ name: map.name, group: 'myMaps' })}
        </Overlay>
      )
    })
  }

  public buildGeoLayers() {
    if (!this.props.auth.authenticated || !this.props.geoServerAddr || !this.props.geoLayers) {
      return <></>
    }
    return this.props.geoLayers.map((layer) => {
      const key = `GeoLayer_${layer.layerGroup}:${layer.layerName}`
      return (
        <Overlay key={key} checked={false} name={layer.layerName}>
          <WMSTileLayer
            url={`${this.props.geoServerAddr}/${layer.layerGroup}/wms`}
            service="WMS"
            version="1.1.0"
            request="GetMap"
            layers={key}
            format="image/png"
            transparent={true}
            tiled={true}
          />
        </Overlay>
      )
    })
  }

  public buildLegend() {
    if (this.state.displayLegend !== undefined) {
      if (this.state.displayLegend.includes('KSAT')) {
        return (
          <>
            <div>
              <img className="mapLegend" src={ksatLegend} alt="legendKsat" />
            </div>
          </>
        )
      } else {
        return <></>
      }
    } else {
      return <></>
    }
  }

  public buildProfiles(systemSelected: string) {
    return this.props.profiles.map((profile, i) => {
      if (profile.system === systemSelected) {
        return <VerticalProfile key={'profile_' + i} data={profile} />
      } else {
        return null
      }
    })
  }

  public buildSpots() {
    const zoom = this.state.currentZoom === undefined ? this.state.settings.zoom : this.state.currentZoom
    return this.props.spots.map((spot) => {
      return (
        <SimpleAsset
          key={'spot_' + spot.imcid}
          data={spot}
          icon={new SpotIcon()}
          setAssetSelected={(v: IAsset | undefined) => this.setAssetSelected(v)}
          currentZoom={zoom}
          symbolType={this.props.preferenceSymbolType}
          coordsDisplayFormat={this.props.preferenceCoordsDisplayFormat}
        />
      )
    })
  }

  public buildCcus() {
    const zoom = this.state.currentZoom === undefined ? this.state.settings.zoom : this.state.currentZoom
    return this.props.ccus.map((ccu) => {
      return (
        <SimpleAsset
          key={'ccu_' + ccu.name}
          data={ccu}
          icon={new PCIcon()}
          setAssetSelected={(v: IAsset | undefined) => this.setAssetSelected(v)}
          currentZoom={zoom}
          symbolType={this.props.preferenceSymbolType}
          coordsDisplayFormat={this.props.preferenceCoordsDisplayFormat}
        />
      )
    })
  }

  public buildVehicles() {
    const zoom = this.state.currentZoom === undefined ? this.state.settings.zoom : this.state.currentZoom
    return this.props.vehicles.map((vehicle) => {
      return (
        <Vehicle
          key={'vehicle_' + vehicle.imcid}
          data={vehicle}
          currentTime={this.state.currentTime}
          isVehiclesLayerActive={this.state.isVehiclesLayerActive}
          setAssetSelected={(v: IAsset | undefined) => this.setAssetSelected(v)}
          currentZoom={zoom}
          symbolType={this.props.preferenceSymbolType}
          coordsDisplayFormat={this.props.preferenceCoordsDisplayFormat}
        />
      )
    })
  }

  public buildFollowAssetPath() {
    if (this.state.isFollowAssetLayerActive) {
      return <FollowAsset isFollowAsstLayerActive={this.state.isFollowAssetLayerActive} />
    } else {
      return <></>
    }
  }

  public buildPollutionMarkers() {
    let pollution = this.props.pollution
    let obstacle = this.props.obstacle
    const pollutionSample = this.props.pollutionSample
    if (this.map) {
      const mapBounds = this.map.leafletElement.getBounds()
      pollution = pollution.filter((pollution) => mapBounds.contains([pollution.latitude, pollution.longitude]))
      obstacle = obstacle.filter((o) => mapBounds.contains([o.positions[0][0], o.positions[0][1]]))
    }

    if (this.state.isPollutionLayerActive) {
      return (
        <Pollution
          pollutionMarkers={pollution}
          locationSelected={this.state.pollutionLocation}
          pollutionOpen={this.state.pollutionOpen}
          addCircle={this.handleAddPollutionCircle}
          removeCircle={this.handleRemovePollutionCircle}
          obstacleLocationSelected={this.state.obstacleLocation}
          obstaclePolygons={obstacle}
          setObstacle={this.handleSelectedObstacle}
          trajectoryLocation={this.state.pollutionTrajectoryLocation}
          trajectoryLocationOpen={this.state.pollutionTrajectoryLocationOpen}
          assetTrajectory={this.state.assetTrajectory}
          pollutionSamples={pollutionSample}
          pollutionTrajectories={this.state.pollutionTrajectories}
          isPollutionTrajectoryModalOpen={this.state.isPollutionTrajectoryModalOpen}
          togglePollutionTrajectoriesModal={this.togglePollutionTrajectoriesModal}
          fetchPollutionTrajectoryWaypoints={this.fetchPollutionTrajectoryWaypoints}
          displayPollutionTrajectory={this.state.isPollutionTrajectorySelected}
        />
      )
    } else {
      return <></>
    }
  }

  public buildPollutionDialog() {
    if (this.state.isPollutionLayerActive) {
      return (
        <div className={this.state.isPollutionTrajectoryModalOpen ? 'pollutionDialog-hided' : 'pollutionDialog'}>
          <form className="pollutionForm">
            {isL0(this.props.auth) || isL1(this.props.auth) || isL2(this.props.auth) || isL3(this.props.auth) ? (
              <div>
                {!this.state.pollutionEnable ? (
                  <Button className="m-1" color="info" size="sm" onClick={() => this.enablePollutionMarker()}>
                    New Pollution Marker
                  </Button>
                ) : (
                  <>
                    <span className="pollutionSpan">New Pollution Marker</span>
                    <input
                      type="text"
                      id="pollutionDescription"
                      value={this.state.pollutionDescription}
                      placeholder="Description"
                      onChange={this.handleChangePollutionDescription}
                    />

                    <label htmlFor="pollutionRadius">Radius (meters)</label>
                    <input
                      type="number"
                      id="pollutionRadius"
                      value={this.state.pollutionRadius}
                      placeholder="Radius (meters)"
                      onChange={this.handleChangePollutionRadius}
                    />

                    <div className="pollutionBtn">
                      <Button className="m-1" color="success" size="sm" onClick={() => this.addPollutionMarker()}>
                        Add
                      </Button>
                      <Button className="m-1" color="danger" size="sm" onClick={() => this.disablePollutionMarker()}>
                        Cancel
                      </Button>
                    </div>
                    <hr />
                  </>
                )}

                {!this.state.pollutionSyncSelector ? (
                  <Button
                    className="m-1"
                    color="warning"
                    size="sm"
                    onClick={() => this.selectTrajectoryPollutionMarkers()}
                  >
                    Sync Pollution Markers
                  </Button>
                ) : (
                  <div>
                    <hr />
                    <label>Trajectory location</label>
                    {this.state.pollutionTrajectoryLocation && (
                      <>
                        <span>{this.state.pollutionTrajectoryLocation.latitude}</span>
                        <span>{this.state.pollutionTrajectoryLocation.longitude}</span>
                      </>
                    )}

                    {this.state.pollutionTrajectoryLocationOpen ? (
                      <i
                        className="fas fa-check"
                        title="Done"
                        onClick={() =>
                          this.setState({
                            pollutionTrajectoryLocationOpen: !this.state.pollutionTrajectoryLocationOpen,
                          })
                        }
                      />
                    ) : (
                      <i
                        className="fas fa-map-marked-alt"
                        title="Select coordinates"
                        onClick={() => this.selectPollutionTrajectoryLocation()}
                      />
                    )}

                    <div className="pollution-trajectory-timestamp">
                      <label htmlFor="trajectory-timestamp">Trajectory timestamp</label>
                      <DatePicker
                        id={'trajectory-timestamp'}
                        className="trajectory-input-date"
                        selected={this.state.pollutionTrajectoryTimestamp}
                        onChange={(newDate: Date) => this.setState({ pollutionTrajectoryTimestamp: newDate })}
                        showTimeSelect={true}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        timeCaption="time"
                        minDate={new Date(this.state.pollutionTrajectoryTimestamp)}
                        maxDate={
                          new Date(
                            this.state.pollutionTrajectoryTimestamp.getFullYear(),
                            this.state.pollutionTrajectoryTimestamp.getMonth(),
                            this.state.pollutionTrajectoryTimestamp.getDate() + 4
                          )
                        }
                        timeIntervals={60}
                        disabled={false}
                      />
                    </div>

                    <div className="pollution-trajectory-markers">
                      <label htmlFor="pollutionMarkersSelected">Pollution markers selected</label>
                      <ul id="pollutionMarkersSelected">
                        {this.state.pollutionOpen.map((p, i) => {
                          return <li key={i}>{p.id}</li>
                        })}
                      </ul>
                    </div>

                    {this.state.pollutionOpen.length > 0 &&
                      this.state.pollutionTrajectoryTimestamp &&
                      this.state.pollutionTrajectoryLocation && (
                        <Button className="m-1" color="warning" size="sm" onClick={() => this.sendPollutionAlert()}>
                          Create trajectory
                        </Button>
                      )}

                    <Button
                      className="m-1"
                      color="warning"
                      size="sm"
                      onClick={() =>
                        this.setState({
                          pollutionSyncSelector: !this.state.pollutionSyncSelector,
                          pollutionTrajectoryLocation: undefined,
                        })
                      }
                    >
                      close
                    </Button>

                    <hr />
                  </div>
                )}

                <div className="obstacleForm">
                  <span className="pollutionSpan">Obstacles</span>
                  {!this.state.obstacleEnable ? (
                    <Button className="m-1" color="info" size="sm" onClick={() => this.drawObstacle()}>
                      New Obstacle
                    </Button>
                  ) : (
                    <div>
                      <input
                        type="text"
                        id="obstacleDescription"
                        value={this.state.obstacleDescription}
                        placeholder="Description"
                        onChange={this.handleChangeObstacleDescription}
                      />

                      <Button className="m-1" color="success" size="sm" onClick={() => this.addObstaclePolygon()}>
                        Add
                      </Button>
                      <Button
                        className="m-1"
                        color="danger"
                        size="sm"
                        onClick={() => this.setState({ obstacleEnable: false, obstacleLocation: [] })}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>

                <div className="trajectoryForm">
                  <span className="pollutionSpan">Trajectories</span>
                  <Button className="m-1" color="info" size="sm" onClick={this.handleListTrajectories}>
                    List trajectories
                  </Button>

                  {this.state.isPollutionTrajectorySelected ? (
                    <Button
                      className="m-1"
                      color="warning"
                      size="sm"
                      onClick={() =>
                        this.setState({ isPollutionTrajectorySelected: !this.state.isPollutionTrajectorySelected })
                      }
                    >
                      Clean trajectory
                    </Button>
                  ) : (
                    <></>
                  )}

                  {!this.state.isFollowAssetOpen ? (
                    <Button className="m-1" color="info" size="sm" onClick={this.handleFollowAsset}>
                      Follow asset
                    </Button>
                  ) : (
                    <>
                      <Button className="m-1" color="info" size="sm" onClick={this.handleUnfollowAsset}>
                        Unfollow asset
                      </Button>

                      <label htmlFor="trajectory-timestamp">Follow asset since</label>
                      <div className="pollution-trajectory-asset-timestamp">
                        <DatePicker
                          id={'trajectory-timestamp'}
                          className="trajectory-input-date"
                          selected={this.state.pollutionFollowAssetSinceTimestamp}
                          onChange={(newDate: Date) => this.handleAssetTrajectoryDatepicker(newDate)}
                          showTimeSelect={true}
                          dateFormat="MMMM d, yyyy h:mm aa"
                          timeCaption="time"
                          maxDate={new Date(this.state.pollutionTrajectoryTimestamp)}
                          excludeScrollbar={true}
                          /*
                              minDate={
                                new Date(
                                  this.state.pollutionTrajectoryTimestamp.getFullYear(),
                                  this.state.pollutionTrajectoryTimestamp.getMonth(),
                                  this.state.pollutionTrajectoryTimestamp.getDate()
                                )
                              }
                              */
                          timeIntervals={15}
                          disabled={false}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}

            {this.state.editPollutionMarker !== undefined && !this.state.pollutionSyncSelector ? (
              <div className="pollutionUpdateMarker">
                <hr />

                <label htmlFor="pollutionStatus">Status</label>
                <span id="pollutionStatus">{this.state.editPollutionMarker.status} </span>

                <label htmlFor="pollutionDateUpdate">Date</label>
                <span id="pollutionDateUpdate">
                  {DateService.timestampMsToReadableDate(this.state.editPollutionMarker.timestamp)}{' '}
                </span>

                <label htmlFor="pollutionDescriptionUpdate">Description</label>
                <input
                  type="text"
                  id="pollutionDescriptionUpdate"
                  value={this.state.pollutionDescriptionUpdate}
                  placeholder="Description"
                  onChange={this.handleChangePollutionDescriptionUpdate}
                  disabled={this.state.editPollutionMarker.status !== 'Created' ? true : false}
                />

                <label htmlFor="pollutionRadiusUpdate">Radius (meters)</label>
                <input
                  type="number"
                  id="pollutionRadiusUpdate"
                  value={this.state.pollutionRadiusUpdate}
                  placeholder="Radius (meters)"
                  onChange={this.handleChangePollutionRadiusUpdate}
                  disabled={this.state.editPollutionMarker.status !== 'Created' ? true : false}
                />

                <div>
                  <label htmlFor="pollutionLatitudeUpdate">Latitude</label>
                  <input
                    type="number"
                    id="pollutionLatitudeUpdate"
                    value={this.state.pollutionLatitudeUpdate}
                    placeholder="Latitude"
                    onChange={this.handleChangePollutionLatitudeUpdate}
                    disabled={this.state.editPollutionMarker.status !== 'Created' ? true : false}
                  />

                  <label htmlFor="pollutionLongitudeUpdate">Longitude</label>
                  <input
                    type="number"
                    id="pollutionLongitudeUpdate"
                    value={this.state.pollutionLongitudeUpdate}
                    placeholder="Longitude"
                    onChange={this.handleChangePollutionLongitudeUpdate}
                    disabled={this.state.editPollutionMarker.status !== 'Created' ? true : false}
                  />
                </div>

                {(isL0(this.props.auth) || isL1(this.props.auth) || isL2(this.props.auth) || isL3(this.props.auth)) &&
                this.state.editPollutionMarker.status === 'Created' ? (
                  <div className="pollutionBtn">
                    <Button
                      className="m-1"
                      color="success"
                      size="sm"
                      onClick={() => this.updatePollutionMarker(this.state.editPollutionMarker)}
                    >
                      Update Pollution Marker
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}

            {isL0(this.props.auth) && !this.state.pollutionConfig && this.state.editPollutionMarker === undefined ? (
              <div>
                <hr />
                <Button className="m-1" color="success" size="sm" onClick={() => this.handlePollutionConfig()}>
                  Config External Server
                </Button>
              </div>
            ) : isL0(this.props.auth) && this.state.pollutionConfig ? (
              <div>
                <hr />
                <label htmlFor="pollutionConfigUpdate">External server</label>
                <input
                  type="text"
                  id="pollutionConfigUpdate"
                  value={this.state.editPollutionConfig}
                  placeholder="IP address"
                  onChange={this.handleChangePollutionConfig}
                />

                <Button className="m-1" color="success" size="sm" onClick={() => this.handleSavePollutionConfig()}>
                  Save
                </Button>

                <Button className="m-1" color="success" size="sm" onClick={() => this.handleClosePollutionConfig()}>
                  Cancel
                </Button>
              </div>
            ) : (
              <></>
            )}

            {(isL0(this.props.auth) || isL1(this.props.auth) || isL2(this.props.auth) || isL3(this.props.auth)) &&
            this.state.editPollutionMarker !== undefined &&
            (this.state.editPollutionMarker.status === 'Created' ||
              this.state.editPollutionMarker.status === 'Done') ? (
              <div>
                <Button className="m-1" color="danger" size="sm" onClick={() => this.togglePollutionModal()}>
                  Delete Pollution Marker
                </Button>
              </div>
            ) : (
              <></>
            )}

            {(isL0(this.props.auth) || isL1(this.props.auth) || isL2(this.props.auth) || isL3(this.props.auth)) &&
            this.state.editObstacle !== undefined ? (
              <div>
                <Button className="m-1" color="danger" size="sm" onClick={() => this.toggleObstacleModal()}>
                  Delete Obstacle
                </Button>
              </div>
            ) : (
              <></>
            )}
          </form>

          <Modal
            id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'}
            isOpen={this.state.isPollutionModalOpen}
            toggle={this.togglePollutionModal}
          >
            <ModalHeader toggle={this.togglePollutionModal}>Remove Focus of Pollution</ModalHeader>
            <ModalBody>The focus of pollution will be removed permanently. Do you want to continue?</ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={() => this.handleDeletePollution()}>
                Yes
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'}
            isOpen={this.state.isObstacleModalOpen}
            toggle={this.toggleObstacleModal}
          >
            <ModalHeader toggle={this.toggleObstacleModal}>Remove Obstacle</ModalHeader>
            <ModalBody>The obstacle will be removed permanently. Do you want to continue?</ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={() => this.handleDeleteObstacle()}>
                Yes
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )
    }
  }

  public drawObstacle() {
    this.disablePollutionMarker()
    NotificationManager.info('Draw obstacle')
    this.setState({ obstacleEnable: true })
  }

  public async addObstaclePolygon() {
    if (this.state.obstacleLocation.length <= 2) {
      NotificationManager.warning('The obstacle polygon must have \nat least 3 points')
      return
    }

    const allPositions: number[][] = []
    this.state.obstacleLocation.forEach((o) => {
      const pos: number[] = []
      pos.push(o.latitude)
      pos.push(o.longitude)
      allPositions.push(pos)
    })

    try {
      const newObstaclePolygon = new IObstacle(
        this.state.obstacleDescription,
        allPositions,
        Date.now(),
        this.props.auth.currentUser.email
      )

      const response = await this.pollutionService.addObstacle(newObstaclePolygon)
      if (response.status === 'success') {
        NotificationManager.success(response.message)
        this.setState({ obstacleEnable: false, obstacleLocation: [] })
      } else {
        NotificationManager.warning('Obstacle polygon cannot be added')
      }
    } catch (error) {
      NotificationManager.warning('Obstacle polygon cannot be added')
    }
  }

  public togglePollutionModal() {
    this.setState((prevState) => ({
      isPollutionModalOpen: !prevState.isPollutionModalOpen,
    }))
  }

  public toggleObstacleModal() {
    this.setState((prevState) => ({
      isObstacleModalOpen: !prevState.isObstacleModalOpen,
    }))
  }

  public async handleDeletePollution() {
    if (this.state.editPollutionMarker) {
      this.togglePollutionModal()
      try {
        const response = await this.pollutionService.deletePollution(this.state.editPollutionMarker.id)
        if (response.status === 'Success') {
          NotificationManager.success(response.message)
          this.handleRemovePollutionCircle(this.state.editPollutionMarker)

          // update redux store
          this.props.setPollutionMarkers()
        } else {
          NotificationManager.warning(response.message)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  public async handleDeleteObstacle() {
    if (this.state.editObstacle) {
      this.toggleObstacleModal()

      try {
        const response = await this.pollutionService.deleteObstacle(this.state.editObstacle.id)
        if (response.status === 'Success') {
          NotificationManager.success(response.message)
          this.setState({ editObstacle: undefined })

          // update redux store
          this.props.setObstacles()
        } else {
          NotificationManager.warning(response.message)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      NotificationManager.warning('Please select an obstacle')
    }
  }

  public handlePollutionConfig() {
    NotificationManager.info('Please specify the server for where\n the pollution markers should be send')
    this.setState({ pollutionConfig: true })
  }

  public handleChangePollutionConfig(event: any) {
    this.setState({ editPollutionConfig: event.target.value })
  }

  public async handleSavePollutionConfig() {
    try {
      const response = await this.pollutionService.updatePollutionExternalServer(this.state.editPollutionConfig)
      if (response.status === 'success') {
        NotificationManager.success('Pollution server updated')
        this.setState({ pollutionConfig: false })
      } else {
        NotificationManager.warning('Pollution server cannot be updated')
      }
    } catch (error) {
      NotificationManager.warning('Pollution server cannot be updated')
    }
  }

  public handleClosePollutionConfig() {
    this.setState({ pollutionConfig: false })
  }

  public handleAddPollutionCircle(marker: IPollution) {
    this.setState({
      pollutionOpen: [...this.state.pollutionOpen, marker],
      editPollutionMarker: marker,
    })
    this.handlePollutionEdit(this.state.editPollutionMarker)
  }

  public handleRemovePollutionCircle(marker: IPollution) {
    const pollutionArray = [...this.state.pollutionOpen]
    const index = pollutionArray.indexOf(marker)
    if (index !== -1) {
      pollutionArray.splice(index, 1)
      this.setState({ pollutionOpen: pollutionArray })
    }
    if (this.state.editPollutionMarker !== undefined) {
      if (this.state.pollutionOpen.length > 0) {
        this.setState({
          editPollutionMarker: this.state.pollutionOpen[this.state.pollutionOpen.length - 1],
        })
        this.handlePollutionEdit(this.state.editPollutionMarker)
      } else {
        this.setState({
          editPollutionMarker: undefined,
          pollutionDescriptionUpdate: '',
          pollutionRadiusUpdate: 20,
        })
      }
    }
  }

  public handleSelectedObstacle(obstacle: IObstacle) {
    this.setState({ editObstacle: obstacle })
  }

  public handleChangePollutionDescription(event: any) {
    this.setState({ pollutionDescription: event.target.value })
  }
  public handleChangePollutionRadius(event: any) {
    this.setState({ pollutionRadius: event.target.value })
  }
  public handleChangePollutionDescriptionUpdate(event: any) {
    this.setState({ pollutionDescriptionUpdate: event.target.value })
  }
  public handleChangePollutionRadiusUpdate(event: any) {
    this.setState({ pollutionRadiusUpdate: event.target.value })
  }
  public handleChangePollutionLatitudeUpdate(event: any) {
    this.setState({ pollutionLatitudeUpdate: event.target.value })
  }
  public handleChangePollutionLongitudeUpdate(event: any) {
    this.setState({ pollutionLongitudeUpdate: event.target.value })
  }
  public handleChangeObstacleDescription(event: any) {
    this.setState({ obstacleDescription: event.target.value })
  }

  public handlePollutionEdit(pollutionMarker: IPollution | undefined) {
    this.setState({ editPollutionMarker: pollutionMarker })
    if (pollutionMarker !== undefined) {
      this.setState({
        pollutionDescriptionUpdate: pollutionMarker.description,
        pollutionRadiusUpdate: pollutionMarker.radius,
        pollutionLatitudeUpdate: pollutionMarker.latitude,
        pollutionLongitudeUpdate: pollutionMarker.longitude,
      })
    }
  }

  public async addPollutionMarker() {
    if (this.state.pollutionRadius < 20) {
      NotificationManager.warning('The radius must be greater than 20')
    } else {
      if (this.state.pollutionLocation) {
        try {
          const newPollutionMarker = new IPollution(
            this.state.pollutionDescription,
            this.state.pollutionRadius,
            this.state.pollutionLocation.latitude,
            this.state.pollutionLocation.longitude,
            Date.now(),
            'Created',
            this.props.auth.currentUser.email
          )
          const response = await this.pollutionService.updatePollution(newPollutionMarker, -1)
          if (response.status === 'success') {
            NotificationManager.success('Pollution marker added')
            this.setState({
              pollutionEnable: false,
              pollutionLocation: undefined,
              pollutionDescription: '',
              pollutionRadius: 20,
            })
          } else {
            NotificationManager.warning('Pollution marker cannot be added')
          }
        } catch (error) {
          NotificationManager.warning('Pollution marker cannot be added')
        }
      } else {
        NotificationManager.warning('No selected location')
      }
    }
  }

  public async updatePollutionMarker(marker: IPollution | undefined) {
    if (this.state.pollutionRadiusUpdate < 20) {
      NotificationManager.warning('The radius must be greater than 20')
    } else {
      if (marker !== undefined) {
        try {
          const newPollutionMarker = new IPollution(
            this.state.pollutionDescriptionUpdate,
            this.state.pollutionRadiusUpdate,
            this.state.pollutionLatitudeUpdate,
            this.state.pollutionLongitudeUpdate,
            marker.timestamp,
            marker.status,
            marker.user
          )
          const response = await this.pollutionService.updatePollution(newPollutionMarker, marker.id)
          if (response.status === 'success') {
            NotificationManager.success('Pollution marker updated')
            this.setState({
              pollutionEnable: false,
              pollutionLocation: undefined,
              pollutionDescriptionUpdate: '',
              pollutionRadiusUpdate: 20,
              editPollutionMarker: undefined,
            })
            this.handleRemovePollutionCircle(marker)
          } else {
            NotificationManager.warning('Pollution marker cannot be updated')
          }
        } catch (error) {
          NotificationManager.warning('Pollution marker cannot be updated')
        }
      } else {
        NotificationManager.error('Please select pollution marker')
      }
    }
  }

  public async selectTrajectoryPollutionMarkers() {
    NotificationManager.info('Select the pollution markers in order to create a trajectory.')
    this.setState({ pollutionSyncSelector: !this.state.pollutionSyncSelector })
  }

  private async sendPollutionAlert() {
    if (this.state.editPollutionConfig.length === 0) {
      NotificationManager.warning('The server is not defined. \nPlease contact an administrator')
    } else {
      if (this.state.pollutionTrajectoryLocation) {
        let markersValid: boolean = true
        const markersID: number[] = []
        this.state.pollutionOpen.forEach((p) => {
          if (p.status === 'Exec' || p.status === 'Done') {
            markersValid = false
            return
          } else {
            markersID.push(p.id)
          }
        })

        if (markersValid) {
          try {
            const response = await this.pollutionService.syncPollutionMarkers(
              this.state.editPollutionConfig,
              this.state.pollutionTrajectoryTimestamp,
              this.state.pollutionTrajectoryLocation.latitude,
              this.state.pollutionTrajectoryLocation.longitude,
              markersID
            )
            if (response.status === 'success') {
              NotificationManager.success(response.message)
              this.setState({
                pollutionTrajectoryLocationOpen: false,
                pollutionSyncSelector: false,
                pollutionOpen: [],
                pollutionTrajectoryLocation: undefined,
                editPollutionMarker: undefined,
              })
              this.props.setPollutionMarkers()
            } else {
              NotificationManager.error(response.message)
            }
          } catch (error) {
            NotificationManager.warning('Pollution markers cannot be synched')
          }
        } else {
          NotificationManager.warning('Markers selected already visited.')
        }
      }
    }
  }

  private selectPollutionTrajectoryLocation() {
    NotificationManager.info('Select the coordinates for the trajectory inside the rectangle.')
    this.setState({ pollutionTrajectoryLocationOpen: !this.state.pollutionTrajectoryLocationOpen })
  }

  public enablePollutionMarker() {
    NotificationManager.info('Please select a location \non the map')
    this.setState({ pollutionEnable: true, obstacleEnable: false, obstacleLocation: [] })
  }

  public disablePollutionMarker() {
    this.setState({ pollutionEnable: false, pollutionLocation: undefined })
  }

  public togglePollutionTrajectoriesModal() {
    this.setState({ isPollutionTrajectoryModalOpen: !this.state.isPollutionTrajectoryModalOpen })
  }

  public async fetchPollutionTrajectoryWaypoints(alertID: number) {
    const resp: IPollutionTrajectoryWaypoints[] = await this.pollutionService.fetchTrajectoryWaypoints(alertID)
    if (resp.length > 0) {
      this.setState({ isPollutionTrajectorySelected: true })
    }
    return resp
  }

  public handleListTrajectories() {
    this.pollutionTrajectories()
  }

  public handleFollowAsset() {
    if (this.props.vehicleSelected.length === 0) {
      NotificationManager.info('Select a vehicle to follow')
    } else {
      this.setState({ isFollowAssetOpen: !this.state.isFollowAssetOpen })

      this.updateAssetTrajectory()
      if (!this.followAssetTimer) {
        this.followAssetTimer = window.setInterval(() => {
          if (this.state.isFollowAssetOpen) {
            this.updateAssetTrajectory()
          }
        }, 30000)
      }
    }
  }

  public handleUnfollowAsset() {
    this.setState({ isFollowAssetOpen: !this.state.isFollowAssetOpen, assetTrajectory: [] })
  }

  public handleAssetTrajectoryDatepicker(newDate: Date) {
    this.setState({ pollutionFollowAssetSinceTimestamp: newDate }, this.updateAssetTrajectory)
  }

  private async updateAssetTrajectory() {
    const resp: IAssetTrajectory[] = await this.pollutionService.fetchAssetTrajectory(
      this.props.vehicleSelected,
      this.state.pollutionFollowAssetSinceTimestamp
    )
    this.setState({ assetTrajectory: resp })
  }

  private async pollutionTrajectories() {
    const resp: IPollutionTrajectory[] = await this.pollutionService.fetchPollutionTrajectoriesInfo()
    if (resp.length > 0) {
      this.setState({
        pollutionTrajectories: resp,
        isPollutionTrajectoryModalOpen: !this.state.isPollutionTrajectoryModalOpen,
      })
    } else {
      NotificationManager.info('No trajectories available')
    }
  }

  public buildContactMarkers() {
    const zoom = this.state.currentZoom === undefined ? this.state.settings.zoom : this.state.currentZoom
    let contacts = this.props.contacts
    if (this.map) {
      const mapBounds = this.map.leafletElement.getBounds()
      contacts = contacts.filter((contact) => mapBounds.contains([contact.latitude, contact.longitude]))
    }

    if (this.state.isContactLayerActive) {
      return (
        <Contact
          contactMarkers={contacts}
          selectedPosition={this.state.selectedPosition}
          selectPosition={this.handleSelectPosition}
          setContactMarkers={this.props.setContactMarkers}
          coordsDisplayFormat={this.props.preferenceCoordsDisplayFormat}
          symbolType={this.props.preferenceSymbolType}
          currentZoom={zoom}
        />
      )
    }
  }

  public handleSelectPosition(enable: boolean) {
    if (enable) {
      this.setState({ isSelectedPositionEnable: !this.state.isSelectedPositionEnable })
    } else {
      this.setState({ isSelectedPositionEnable: !this.state.isSelectedPositionEnable, selectedPosition: undefined })
    }
  }

  public buildMapElements() {
    const mapElements = this.props.mapElements
    if (this.state.isMapElementLayerActive) {
      return <MapElement mapElements={mapElements} />
    }
  }

  public toggleHighlight() {
    this.setState({
      highlightedShip: '',
    })
  }

  public highlightShip(ship: any) {
    this.setState({
      highlightedShip: ship,
    })
  }

  public buildAisShips() {
    const zoom = this.state.currentZoom === undefined ? this.state.settings.zoom : this.state.currentZoom
    return this.ais.map((ship) => {
      let userOpacity = 0.65
      if (isL0(this.props.auth) || isL1(this.props.auth) || isL2(this.props.auth) || isL3(this.props.auth))
        userOpacity = 0.75
      return (
        <AISShip
          key={'ship_' + ship.mmsi}
          currentTime={this.state.currentTime}
          ship={ship}
          isToDrawAISPolygons={this.state.isToDrawAISPolygons}
          isAISLayerActive={this.state.isAISLayerActive}
          isHighlighted={this.state.highlightedShip === ship.mmsi}
          opacity={userOpacity}
          updateHighlight={() => this.highlightShip(ship.mmsi)}
          currentZoom={zoom}
          symbolType={this.props.preferenceSymbolType}
          coordsDisplayFormat={this.props.preferenceCoordsDisplayFormat}
        />
      )
    })
  }

  public drawCanvas(info: any) {
    if (this.state.currentZoom > 10 || this.state.currentZoom === undefined) {
      const ctx = info.canvas.getContext('2d')
      ctx.clearRect(0, 0, info.canvas.width, info.canvas.height)
      ctx.fillStyle = 'rgba(255,116,0, 0.2)'
      this.props.aisShips.forEach((ship) => {
        const aisCanvas = new AISCanvas({
          perpLinesSize: this.state.perpLinesSize,
          ship,
        })
        let drawMeasure = false
        if (this.state.currentZoom > 15) {
          drawMeasure = true
        }
        aisCanvas.drawInCanvas(info, drawMeasure)
      })
    } else {
      // clear canvas
      const ctx = info.canvas.getContext('2d')
      ctx.clearRect(0, 0, info.canvas.width, info.canvas.height)
    }
  }

  public buildAircrafts() {
    let aircrafts = this.props.aircrafts
    if (this.map) {
      const mapBounds = this.map.leafletElement.getBounds()
      aircrafts = aircrafts.filter((aircraft) => mapBounds.contains([aircraft.latitudeDeg, aircraft.longitudeDeg]))
    }
    const zoom = this.state.currentZoom === undefined ? this.state.settings.zoom : this.state.currentZoom

    if (this.state.isAircraftLayerActive) {
      return (
        <Aircraft
          aircrafts={aircrafts}
          currentZoom={zoom}
          coordsDisplayFormat={this.props.preferenceCoordsDisplayFormat}
        />
      )
    }
  }

  public async handleMove(e: any) {
    if (!this.props.auth.authenticated || !this.map) {
      return
    }
    const center = this.map.leafletElement.getBounds().getCenter()
    const zoom = this.map.leafletElement.getZoom()
    const newSettings: IMapSettings = {
      lat: center.lat,
      lng: center.lng,
      zoom,
    }
    if (this.props.auth.authenticated && !isL4(this.props.auth)) {
      await MapUtils.updateMapSettings(newSettings)
    }
    this.props.selectVehicleLastState(null)
    this.props.selectPlanPosition(null)
  }

  public handleZoom(e: any) {
    const newZoom = e.target._animateToZoom
    let newLineLength = 0
    if (newZoom > 7) {
      newLineLength = 138598 * Math.pow(newZoom, -2.9)
      this.setState({
        perpLinesSize: Math.round(newLineLength),
      })
      if (newZoom > 12) {
        if (!this.state.isToDrawAISPolygons) {
          this.toggleDrawAisLocations()
        }
      } else {
        if (this.state.isToDrawAISPolygons) {
          this.toggleDrawAisLocations()
        }
      }
    }
    this.handleMove(e)
    this.setState({ currentZoom: newZoom })
  }

  public toggleDrawAisLocations() {
    this.setState({
      isToDrawAISPolygons: !this.state.isToDrawAISPolygons,
    })
  }

  private setLeafletMapRef(map: any) {
    this.map = map
  }

  private async onMeasureToggle() {
    if (this.props.toolSelected === ToolSelected.MEASURE) {
      this.props.setToolSelected(ToolSelected.NONE)
      this.props.setSidePanelVisibility(false)
      this.props.clearMeasure()
    } else {
      this.props.setSidePanelVisibility(true)
      this.props.setSidePanelTitle('Measure distance')
      this.props.setSidePanelContent({})
      this.props.setEditVehicle(undefined)
      this.props.setToolSelected(ToolSelected.MEASURE)
    }
  }

  private onToolpickToogle(weatherParam: WeatherParam | null) {
    if (weatherParam !== null) {
      this.props.setToolSelected(ToolSelected.TOOLPICK)
    } else if (this.props.toolSelected === ToolSelected.TOOLPICK) {
      this.props.setToolSelected(ToolSelected.NONE)
    }
    this.props.setWeatherParam(weatherParam)
    this.props.setToolClickLocation(null)
  }

  private onGpsClick() {
    this.props.toggleGps()
  }

  public onMyLocationToggle() {
    if (this.props.toolSelected === ToolSelected.MYLOCATION) {
      this.props.setToolSelected(ToolSelected.NONE)
    } else {
      this.props.setToolSelected(ToolSelected.MYLOCATION)
      NotificationManager.info('Select your position')
    }
    this.setState({ isMyLocationActive: !this.state.isMyLocationActive })
  }

  public onMyLocationClick(clickLocation: ILatLng) {
    NotificationManager.success('Location updated')
    localStorage.setItem('user-lat', clickLocation.latitude.toString())
    localStorage.setItem('user-lng', clickLocation.longitude.toString())
  }

  private onAnnotationToggle() {
    if (this.props.toolSelected === ToolSelected.ANNOTATION) {
      this.props.setToolSelected(ToolSelected.NONE)
    } else {
      this.props.setToolSelected(ToolSelected.ANNOTATION)
    }
    this.props.setSidePanelVisibility(false)
  }

  public buildWeatherSelector() {
    return (
      <UncontrolledDropdown nav={true}>
        <DropdownToggle nav={true} caret={false}>
          <i
            className={'fas fa-map-pin fa-lg ' + (this.props.toolSelected === ToolSelected.TOOLPICK ? 'selected' : '')}
            title="Enable Weather Toolpick"
          />
        </DropdownToggle>
        <DropdownMenu right={false} className="weather-menu">
          <DropdownItem onClick={() => this.onToolpickToogle(WeatherParam.AIR_TEMPERATURE)}>
            Air Temperature
          </DropdownItem>
          <DropdownItem onClick={() => this.onToolpickToogle(WeatherParam.CURRENT_DIRECTION)}>
            Current Direction
          </DropdownItem>
          <DropdownItem onClick={() => this.onToolpickToogle(WeatherParam.CURRENT_SPEED)}>Current Speed</DropdownItem>
          <DropdownItem onClick={() => this.onToolpickToogle(WeatherParam.GUST)}>Wind gust</DropdownItem>
          <DropdownItem onClick={() => this.onToolpickToogle(WeatherParam.WATER_TEMPERATURE)}>
            Water Temperature
          </DropdownItem>
          <DropdownItem onClick={() => this.onToolpickToogle(WeatherParam.WAVE_DIRECTION)}>Wave Direction</DropdownItem>
          <DropdownItem onClick={() => this.onToolpickToogle(WeatherParam.WAVE_HEIGHT)}>Wave Height</DropdownItem>
          <DropdownItem onClick={() => this.onToolpickToogle(WeatherParam.WIND_DIRECTION)}>Wind Direction</DropdownItem>
          <DropdownItem onClick={() => this.onToolpickToogle(WeatherParam.WIND_SPEED)}>Wind Speed</DropdownItem>
          <DropdownItem onClick={() => this.onToolpickToogle(null)}>None</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }

  public toggleLayerControl() {
    const elements = Array.from(
      document.getElementsByClassName('leaflet-control-layers-toggle') as HTMLCollectionOf<HTMLElement>
    )

    for (const element of elements) {
      element.style.display = 'none'
    }

    this.props.sidebar.toggleSidebar(this.props.auth)

    const toggle = Array.from(document.getElementsByClassName('layer-control-toggle') as HTMLCollectionOf<HTMLElement>)
    if (toggle !== undefined && toggle !== null && toggle.length > 0)
      toggle[0].className === 'layer-control-toggle'
        ? (toggle[0].className = 'layer-control-toggle open-sidebar')
        : (toggle[0].className = 'layer-control-toggle')

    Array.from(toggle[0].children as HTMLCollectionOf<HTMLElement>).forEach((child) => {
      if (child !== undefined && child !== null && child.className.includes('fa-angle'))
        child.className === 'fas fa-angle-double-left fa-lg'
          ? (child.className = 'fas fa-angle-double-right fa-lg')
          : (child.className = 'fas fa-angle-double-left fa-lg')
    })
  }

  public render() {
    const elements = Array.from(
      document.getElementsByClassName('leaflet-control-layers-toggle') as HTMLCollectionOf<HTMLElement>
    )
    for (const element of elements) {
      element.style.display = 'none'
    }

    const zoom = this.state.currentZoom === undefined ? this.state.settings.zoom : this.state.currentZoom

    return (
      <>
        <LeafletMap
          ref={(m) => this.setLeafletMapRef(m)}
          fullscreenControl={true}
          center={{ lat: this.state.settings.lat, lng: this.state.settings.lng }}
          zoom={this.state.settings.zoom}
          maxZoom={19}
          onClick={this.handleMapClick}
          onMoveend={this.handleMove}
          onZoomend={this.handleZoom}
          onOverlayAdd={(evt: any) => {
            if (
              evt.name === 'Bathymetry - MOHID' ||
              evt.name === 'Ocean Front Identification' ||
              evt.name === 'Ocean Eddy Identification' ||
              evt.name === 'Sea Surface Salinity - MOHID' ||
              evt.name === 'Sea Surface Current - MOHID' ||
              evt.name === 'Sea Surface Temperature - MOHID'
            ) {
              const colab = Array.from(document.getElementsByClassName('colab-logo') as HTMLCollectionOf<HTMLElement>)
              if (colab[0] !== null && colab[0] !== undefined) {
                colab[0].className = colab[0].className + ' colab-logo-show'
              }
            }
            // this is done for perfomance reasons
            if (evt.name === 'AIS Data') {
              this.setState({ isAISLayerActive: true })
            } else if (evt.name === 'Aircraft Data') {
              this.setState({ isAircraftLayerActive: true })
            } else if (evt.name === 'Vehicles') {
              this.setState({ isVehiclesLayerActive: true })
            } else if (
              evt.name.startsWith('Copernicus') ||
              evt.name === 'Sea Surface Temperature' ||
              evt.name === 'Sea Surface Salinity' ||
              evt.name === 'Sea Surface Velocity' ||
              evt.name === 'Chl Concentration' ||
              evt.name === 'Waves' ||
              evt.name === 'Wind' ||
              evt.name === 'Sea Level Anomaly'
            ) {
              this.props.setMapOverlayInfo(evt.name)
              return
            } else if (evt.name === 'Pollution Data') {
              this.setState({ isPollutionLayerActive: true })
            } else if (evt.name === 'Contacts') {
              this.setState({ isContactLayerActive: true })
            } else if (evt.name === 'Map Elements') {
              this.setState({ isMapElementLayerActive: true })
              this.props.setEditingMapElement(true)
              NotificationManager.info('Use the draw tools to define area')
            } else if (evt.name === 'Follow Asset') {
              this.setState({ isFollowAssetLayerActive: true })
            } else if (evt.name.includes('KSAT')) {
              this.setState({ displayLegend: 'KSAT' })
            }
          }}
          onOverlayRemove={(evt: any) => {
            if (
              evt.name === 'Bathymetry - MOHID' ||
              evt.name === 'Ocean Front Identification' ||
              evt.name === 'Ocean Eddy Identification' ||
              evt.name === 'Sea Surface Salinity - MOHID' ||
              evt.name === 'Sea Surface Current - MOHID' ||
              evt.name === 'Sea Surface Temperature - MOHID'
            ) {
              const colab = Array.from(document.getElementsByClassName('colab-logo') as HTMLCollectionOf<HTMLElement>)
              if (colab[0] !== null && colab[0] !== undefined) {
                colab[0].className = colab[0].className.replace(' colab-logo-show', '')
              }
            }
            if (evt.name === 'AIS Data') {
              this.setState({ isAISLayerActive: false })
            } else if (evt.name === 'Aircraft Data') {
              this.setState({ isAircraftLayerActive: false })
            } else if (evt.name === 'Vehicles') {
              this.setState({ isVehiclesLayerActive: false })
            } else if (
              evt.name.startsWith('Copernicus') ||
              evt.name === 'Sea Surface Temperature' ||
              evt.name === 'Sea Surface Salinity' ||
              evt.name === 'Sea Surface Velocity' ||
              evt.name === 'Chl Concentration' ||
              evt.name === 'Waves' ||
              evt.name === 'Wind' ||
              evt.name === 'Sea Level Anomaly'
            ) {
              this.props.setMapOverlayInfo('')
            } else if (evt.name === 'Pollution Data') {
              this.setState({ isPollutionLayerActive: false })
            } else if (evt.name === 'Contacts') {
              this.setState({
                isContactLayerActive: false,
                selectedPosition: undefined,
                isSelectedPositionEnable: false,
              })
            } else if (evt.name === 'Map Elements') {
              this.setState({ isMapElementLayerActive: false })
              this.props.setEditingMapElement(false)
            } else if (evt.name === 'Follow Asset') {
              this.setState({ isFollowAssetLayerActive: false })
            } else if (evt.name.includes('KSAT')) {
              if (this.state.displayLegend && this.state.displayLegend.includes('KSAT')) {
                this.setState({ displayLegend: undefined })
              }
            }
          }}
        >
          <div className="layer-control-toggle">
            <i className="fas fa-angle-double-left fa-lg" onClick={() => this.toggleLayerControl()} />
          </div>

          <LayersControl id={this.props.isDarkMode ? 'darkmode' : ''} position="topright" collapsed={false}>
            <BaseLayer checked={true} name="OpenStreetMap">
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url={
                  this.props.isDarkMode
                    ? 'https://server.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}'
                    : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                }
              />
            </BaseLayer>

            <BaseLayer name="ArcGIS NatGeo">
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}"
                maxZoom={16}
                attribution="Map data &copy; Esri &mdash; National Geographic, Esri, DeLorme, NAVTEQ, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, iPC"
                id="examples.map-i875mjb7"
              />
            </BaseLayer>

            <BaseLayer name="ArcGIS Ocean">
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}"
                attribution="Tiles &copy; ESRI"
                maxZoom={10}
              />
            </BaseLayer>

            <BaseLayer name="ArcGis World Imagery">
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                attribution="Tiles &copy; ESRI"
              />
            </BaseLayer>

            <BaseLayer name="Thunder Forest">
              <TileLayer
                url="https://{s}.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=c4d207cad22c4f65b9adb1adbbaef141"
                attribution="Tiles &copy; ThunderForest"
              />
            </BaseLayer>

            <BaseLayer name="GMRT">
              <WMSTileLayer
                layers="gmrt"
                url="https://www.gmrt.org/services/mapserver/wms_merc?service=WMS&version=1.0.0&request=GetMap"
                attribution="GEBCO (multiple sources)"
              />
            </BaseLayer>

            <Overlay checked={true} name="Vehicles">
              <LayerGroup>{this.buildVehicles()}</LayerGroup>
              {this.buildEditVehicleModal()}
            </Overlay>

            <Overlay checked={true} name="Spots">
              <LayerGroup>{this.buildSpots()}</LayerGroup>
            </Overlay>

            <Overlay checked={true} name="CCUS">
              <LayerGroup>{this.buildCcus()}</LayerGroup>
            </Overlay>

            {this.props.auth.authenticated && (
              <Overlay checked={true} name="Plans">
                <LayerGroup>
                  <PlanManager
                    mapRef={this.map}
                    aisShips={this.ais}
                    currentZoom={zoom}
                    coordsDisplayFormat={this.props.preferenceCoordsDisplayFormat}
                  />
                </LayerGroup>
              </Overlay>
            )}

            <Overlay checked={this.state.isAISLayerActive} name="AIS Data">
              <LayerGroup>
                {this.buildAisShips()}
                <CanvasLayer drawMethod={this.drawCanvas} />
              </LayerGroup>
            </Overlay>
            <Overlay name="AIS Density">
              <WMSTileLayer
                url="https://ows.emodnet-humanactivities.eu/geoserver/ows"
                layers="vesseldensity_allavg"
                attribution="Map data &copy; MarineTraffic"
                format="image/png"
                transparent={true}
                maxZoom={21}
                opacity={0.5}
                maxNativeZoom={10}
              />
            </Overlay>

            {this.props.auth.authenticated && !isL4(this.props.auth) && (
              <Overlay checked={this.state.isAircraftLayerActive} name="Aircraft Data">
                <LayerGroup>{this.buildAircrafts()}</LayerGroup>
                {this.props.addLayer({ name: 'Aircraft Data', group: 'Operator Data' })}
              </Overlay>
            )}

            <Overlay name="Bathymetry">
              <WMSTileLayer
                url="https://ows.emodnet-bathymetry.eu/wms"
                layers="mean_multicolour"
                // layers="mean_multicolour,contours"
                format="image/png"
                transparent={true}
                belowmincolor="extend"
                abovemaxcolor="extend"
                opacity={hasL3Permissions(this.props.auth) ? 0.5 : 0.575}
                attribution="EMODNET"
              />
            </Overlay>
            {this.props.auth.authenticated && !isL4(this.props.auth) && (
              <Overlay checked={this.state.isMapElementLayerActive} name="Map Elements">
                <LayerGroup>{this.buildMapElements()}</LayerGroup>
              </Overlay>
            )}
            {this.props.auth.authenticated && !isL4(this.props.auth) && (
              <Overlay checked={this.state.isFollowAssetLayerActive} name="Follow Asset">
                <LayerGroup>{this.buildFollowAssetPath()}</LayerGroup>
              </Overlay>
            )}

            {/* VerticalProfiles */}
            {this.state.profilesLayers.length === 0 ? (
              <Overlay checked={false} name={'No data available'} key={'profiles-data-empty'}>
                <LayerGroup />
                {this.props.addLayer({ name: 'No data available', group: 'Profiles Data' })}
              </Overlay>
            ) : (
              this.state.profilesLayers.map((profileLayerName) => {
                return (
                  <Overlay
                    checked={false}
                    name={profileLayerName}
                    id={'profile-' + profileLayerName}
                    key={'profile-' + profileLayerName}
                  >
                    <LayerGroup>{this.buildProfiles(profileLayerName)}</LayerGroup>
                    {this.props.addLayer({
                      name: profileLayerName,
                      group: 'Profiles Data',
                      legendStyle: 'rainbow',
                      lowerValue: 0,
                      higherValue: 40,
                      units: '°C',
                    })}
                    {this.props.sidebar.scientistLayers.push(profileLayerName)}
                  </Overlay>
                )
              })
            )}

            <Overlay name="Chl Concentration">
              <WMSTileLayer
                url="https://nrt.cmems-du.eu/thredds/wms/cmems_mod_ibi_bgc_anfc_0.027deg-3D_P1D-m"
                layers={this.props.layersSettings.get('Chl Concentration').activeLayer}
                format="image/png"
                styles={'boxfill/' + this.props.layersSettings.get('Chl Concentration').legendStyle}
                transparent={true}
                logscale="true"
                colorscalerange={
                  this.props.layersSettings.get('Chl Concentration').lowerValue +
                  ',' +
                  this.props.layersSettings.get('Chl Concentration').higherValue
                }
                belowmincolor="extend"
                abovemaxcolor="extend"
                opacity={this.props.layersSettings.get('Chl Concentration').opacity}
                attribution="E.U. Copernicus Marine Service Information"
              />
            </Overlay>

            <Overlay name="Sea Level Anomaly">
              <WMSTileLayer
                url="http://nrt.cmems-du.eu/thredds/wms/dataset-duacs-nrt-global-merged-allsat-phy-l4"
                layers="ugosa"
                format="image/png"
                transparent={true}
                styles={'boxfill/' + this.props.layersSettings.get('Sea Level Anomaly').legendStyle}
                colorscalerange={
                  this.props.layersSettings.get('Sea Level Anomaly').lowerValue +
                  ',' +
                  this.props.layersSettings.get('Sea Level Anomaly').higherValue
                }
                belowmincolor="extend"
                abovemaxcolor="extend"
                opacity={this.props.layersSettings.get('Sea Level Anomaly').opacity}
                attribution="E.U. Copernicus Marine Service Information"
              />
            </Overlay>

            <Overlay name="Sea Surface Salinity">
              <WMSTileLayer
                url="https://nrt.cmems-du.eu/thredds/wms/cmems_mod_ibi_phy_anfc_0.027deg-3D_P1D-m"
                layers="so"
                format="image/png"
                styles={'boxfill/' + this.props.layersSettings.get('Sea Surface Salinity').legendStyle}
                transparent={true}
                colorscalerange={
                  this.props.layersSettings.get('Sea Surface Salinity').lowerValue +
                  ',' +
                  this.props.layersSettings.get('Sea Surface Salinity').higherValue
                }
                belowmincolor="extend"
                abovemaxcolor="extend"
                opacity={this.props.layersSettings.get('Sea Surface Salinity').opacity}
                attribution="E.U. Copernicus Marine Service Information"
              />
            </Overlay>
            <Overlay name="Sea Surface Temperature">
              <WMSTileLayer
                url={
                  this.props.layersSettings
                    .get('Sea Surface Temperature')
                    .subLayers.get(this.props.layersSettings.get('Sea Surface Temperature').activeLayer).url
                }
                layers={this.props.layersSettings.get('Sea Surface Temperature').activeLayer}
                format="image/png"
                styles={
                  this.props.layersSettings
                    .get('Sea Surface Temperature')
                    .subLayers.get(this.props.layersSettings.get('Sea Surface Temperature').activeLayer).style
                }
                transparent={true}
                colorscalerange={
                  this.props.layersSettings.get('Sea Surface Temperature').lowerValue +
                  ',' +
                  this.props.layersSettings.get('Sea Surface Temperature').higherValue
                }
                belowmincolor="extend"
                abovemaxcolor="extend"
                opacity={this.props.layersSettings.get('Sea Surface Temperature').opacity}
                attribution="E.U. Copernicus Marine Service Information"
              />
            </Overlay>
            <Overlay name="Sea Surface Velocity">
              <WMSTileLayer
                url="https://nrt.cmems-du.eu/thredds/wms/cmems_mod_ibi_phy_anfc_0.027deg-3D_P1D-m"
                layers="sea_water_velocity"
                format="image/png"
                styles={'vector/' + this.props.layersSettings.get('Sea Surface Velocity').legendStyle}
                transparent={true}
                colorscalerange={
                  this.props.layersSettings.get('Sea Surface Velocity').lowerValue +
                  ',' +
                  this.props.layersSettings.get('Sea Surface Velocity').higherValue
                }
                belowmincolor="extend"
                abovemaxcolor="extend"
                opacity={this.props.layersSettings.get('Sea Surface Velocity').opacity}
                attribution="E.U. Copernicus Marine Service Information"
              />
            </Overlay>
            <Overlay name="Waves">
              <WMSTileLayer
                url="https://nrt.cmems-du.eu/thredds/wms/dataset-ibi-analysis-forecast-wav-005-005-hourly"
                time={this.lastWaveMapTime}
                styles={'boxfill/' + this.props.layersSettings.get('Waves').legendStyle}
                layers="VHM0"
                colorscalerange={
                  this.props.layersSettings.get('Waves').lowerValue +
                  ',' +
                  this.props.layersSettings.get('Waves').higherValue
                }
                belowmincolor="extend"
                abovemaxcolor="extend"
                transparent={true}
                format="image/png"
                opacity={this.props.layersSettings.get('Waves').opacity}
                attribution="E.U. Copernicus Marine Service Information"
              />
            </Overlay>
            <Overlay name="Wind">
              <WMSTileLayer
                url="https://nrt.cmems-du.eu/thredds/wms/KNMI-GLO-WIND_L3-OBS_HY-2B_HSCAT_50_DES_V2"
                styles={'vector/' + this.props.layersSettings.get('Wind').legendStyle}
                layers="wind"
                elevation={10}
                colorscalerange={
                  this.props.layersSettings.get('Wind').lowerValue +
                  ',' +
                  this.props.layersSettings.get('Wind').higherValue
                }
                belowmincolor="extend"
                abovemaxcolor="extend"
                transparent={true}
                format="image/png"
                opacity={this.props.layersSettings.get('Wind').opacity}
                attribution="E.U. Copernicus Marine Service Information"
              />
            </Overlay>

            <Overlay name="Ocean Front Identification">
              <WMSTileLayer
                url={this.state.threddServerUrl + '/wms/MUR/latest.nc'}
                version="1.3.0"
                styles={'raster/x-Rainbow'}
                layers={this.props.layersSettings.get('Ocean Front Identification').activeLayer}
                colorscalerange={
                  this.props.layersSettings.get('Ocean Front Identification').lowerValue +
                  ',' +
                  this.props.layersSettings.get('Ocean Front Identification').higherValue
                }
                transparent={true}
                format="image/png"
                opacity={this.props.layersSettings.get('Ocean Front Identification').opacity}
                attribution="+ATLANTIC CoLAB"
              />
            </Overlay>

            <Overlay name="Ocean Eddy Identification">
              <WMSTileLayer
                url={this.state.threddServerUrl + '/wms/eddies/latest.nc'}
                version="1.3.0"
                styles={'default-scalar/x-Rainbow'}
                layers="anticyc_shape"
                colorscalerange={
                  this.props.layersSettings.get('Ocean Eddy Identification').lowerValue +
                  ',' +
                  this.props.layersSettings.get('Ocean Eddy Identification').higherValue
                }
                transparent={true}
                format="image/png"
                opacity={this.props.layersSettings.get('Ocean Eddy Identification').opacity}
                attribution="+ATLANTIC CoLAB"
              />
            </Overlay>

            {this.props.auth.authenticated && !isL4(this.props.auth) && (
              <Overlay name="NetCDF File">
                <WMSTileLayer
                  url={
                    this.state.threddServerUrl +
                    '/wms/userFiles/' +
                    this.props.layersSettings.get('NetCDF File').fileName
                  }
                  styles={'raster/default'}
                  layers={this.props.layersSettings.get('NetCDF File').layerName}
                  colorscalerange={
                    this.props.layersSettings.get('NetCDF File').lowerValue +
                    ',' +
                    this.props.layersSettings.get('NetCDF File').higherValue
                  }
                  transparent={true}
                  format="image/png"
                  opacity={this.props.layersSettings.get('NetCDF File').opacity}
                  attribution="LSTS Thredds"
                />
              </Overlay>
            )}
            {this.props.auth.authenticated && !isL4(this.props.auth) && (
              <Overlay name="Sea Surface Salinity - MOHID">
                <WMSTileLayer
                  url={
                    this.state.threddServerUrl +
                    '-maretec/wms/IST_MOHID_BIO_DATA/LISOCEAN_0.003DEG_Surface_1H/FORECAST/' +
                    new Date(Date.now()).toISOString().split('T')[0].replace('-', '').replace('-', '') +
                    '00_Surface.nc'
                  }
                  time={this.lastWaveMapTime}
                  layers={'salinity'}
                  format="image/png"
                  styles={'boxfill/' + this.props.layersSettings.get('Sea Surface Salinity - MOHID').legendStyle}
                  transparent={true}
                  colorscalerange={
                    this.props.layersSettings.get('Sea Surface Salinity - MOHID').lowerValue +
                    ',' +
                    this.props.layersSettings.get('Sea Surface Salinity - MOHID').higherValue
                  }
                  belowmincolor="extend"
                  abovemaxcolor="extend"
                  opacity={this.props.layersSettings.get('Sea Surface Salinity - MOHID').opacity}
                  attribution="+ATLANTIC CoLAB"
                />
              </Overlay>
            )}

            <Overlay name="Sea Surface Current - MOHID">
              <WMSTileLayer
                url={
                  this.state.threddServerUrl +
                  '-maretec/wms/IST_MOHID_BIO_DATA/LISOCEAN_0.003DEG_Surface_1H/FORECAST/' +
                  new Date(Date.now()).toISOString().split('T')[0].replace('-', '').replace('-', '') +
                  '00_Surface.nc'
                }
                time={this.lastWaveMapTime}
                layers={'sea_water_velocity'}
                format="image/png"
                styles={'vector/' + this.props.layersSettings.get('Sea Surface Current - MOHID').legendStyle}
                transparent={true}
                colorscalerange={
                  this.props.layersSettings.get('Sea Surface Current - MOHID').lowerValue +
                  ',' +
                  this.props.layersSettings.get('Sea Surface Current - MOHID').higherValue
                }
                belowmincolor="extend"
                abovemaxcolor="extend"
                opacity={this.props.layersSettings.get('Sea Surface Current - MOHID').opacity}
                attribution="+ATLANTIC CoLAB"
              />
            </Overlay>

            <Overlay name="Sea Surface Temperature - MOHID">
              <WMSTileLayer
                url={
                  this.state.threddServerUrl +
                  '-maretec/wms/IST_MOHID_BIO_DATA/LISOCEAN_0.003DEG_Surface_1H/FORECAST/' +
                  new Date(Date.now()).toISOString().split('T')[0].replace('-', '').replace('-', '') +
                  '00_Surface.nc'
                }
                time={this.lastWaveMapTime}
                layers={'temperature'}
                format="image/png"
                styles={'boxfill/' + this.props.layersSettings.get('Sea Surface Temperature - MOHID').legendStyle}
                transparent={true}
                colorscalerange={
                  this.props.layersSettings.get('Sea Surface Temperature - MOHID').lowerValue +
                  ',' +
                  this.props.layersSettings.get('Sea Surface Temperature - MOHID').higherValue
                }
                belowmincolor="extend"
                abovemaxcolor="extend"
                opacity={this.props.layersSettings.get('Sea Surface Temperature - MOHID').opacity}
                attribution="+ATLANTIC CoLAB"
              />
            </Overlay>

            <Overlay name="Bathymetry - MOHID">
              <WMSTileLayer
                url={
                  this.state.threddServerUrl +
                  '-maretec/wms/IST_MOHID_BIO_DATA/LISOCEAN_0.003DEG_Surface_1H/FORECAST/' +
                  new Date(Date.now()).toISOString().split('T')[0].replace('-', '').replace('-', '') +
                  '00_Surface.nc'
                }
                layers={'bathymetry'}
                format="image/png"
                styles={'boxfill/' + this.props.layersSettings.get('Bathymetry - MOHID').legendStyle}
                transparent={true}
                colorscalerange={
                  this.props.layersSettings.get('Bathymetry - MOHID').lowerValue +
                  ',' +
                  this.props.layersSettings.get('Bathymetry - MOHID').higherValue
                }
                belowmincolor="extend"
                abovemaxcolor="extend"
                opacity={this.props.layersSettings.get('Bathymetry - MOHID').opacity}
                attribution="+ATLANTIC CoLAB"
              />
            </Overlay>

            {/* Other */}
            <Overlay checked={true} name="Annotations">
              <LayerGroup>{this.buildAnnotations()}</LayerGroup>
            </Overlay>

            <Overlay checked={true} name="Current Location">
              <LayerGroup>{this.buildUsersLocations()}</LayerGroup>
            </Overlay>

            <Overlay checked={true} name="Measure Track">
              <LayerGroup>{this.buildMeasureTrack()}</LayerGroup>
            </Overlay>

            {/* MyMaps */}
            {isMyMapsServiceAvailable(this.props.servicesAvailable) && this.buildMyMaps()}
            {this.buildLegend()}
            {this.buildGeoLayers()}
            {/* Projects */}
            {this.props.auth.authenticated && this.props.auth.currentUser.domain.includes('Ramp') && (
              //  && this.props.auth.currentUser.domain.includes('Ramp') && this.map && (
              <Overlay checked={this.state.isPollutionLayerActive} name="Pollution Data">
                <LayerGroup>{this.buildPollutionMarkers()}</LayerGroup>
                {this.buildPollutionDialog()}
              </Overlay>
            )}
            {this.props.auth.authenticated &&
              !isL4(this.props.auth) &&
              this.props.auth.currentUser.domain.includes('REPMUS24') &&
              this.map && (
                <Overlay checked={this.state.isContactLayerActive} name="Contacts">
                  <LayerGroup>{this.buildContactMarkers()}</LayerGroup>
                </Overlay>
              )}
          </LayersControl>

          {this.buildNewAnnotationMarker()}
          {this.buildToolpickMarker()}
          {this.buildRipplesTools()}
          <img src={colabLogo} className="colab-logo" id="colab-logo-off" alt="+Atlantic CoLAB" />
          <CoordinatesControl
            position="bottomleft"
            latLngDefault={{ lat: '41.18', lng: '-8.7' }}
            coordinates={this.props.preferenceCoordsDisplayFormat === 'DD' ? 'decimal' : 'degrees'}
          />
        </LeafletMap>
      </>
    )
  }

  private async fetchMapSettings() {
    const userMapSettings: IMapSettings = await MapUtils.fetchUserMapSettings()
    this.setState({
      settings: {
        lat: userMapSettings.lat,
        lng: userMapSettings.lng,
        zoom: userMapSettings.zoom,
      },
    })
  }

  private buildUsersLocations() {
    let myLocation: any
    if (localStorage.getItem('user-lat') !== null && localStorage.getItem('user-lng') !== null) {
      myLocation = {
        lat: Number(localStorage.getItem('user-lat')),
        lng: Number(localStorage.getItem('user-lng')),
      }
    }

    // resize icon
    const zoom = this.state.currentZoom === undefined ? this.state.settings.zoom : this.state.currentZoom
    const iconSize = 15 + zoom / 2
    this.myLocationIcon.options.iconSize = [iconSize, iconSize]

    return (
      <>
        {this.props.isGpsActive && <ClientLocation onLocationClick={this.onLocationClick} />}
        {this.props.auth.authenticated && myLocation !== undefined && (
          <Marker
            key={'mylocation'}
            position={myLocation}
            onClick={() => this.displayMyLocationInfo(myLocation)}
            icon={this.myLocationIcon}
          />
        )}
        {this.props.auth.authenticated &&
          this.props.usersLocations.map((u: IUserLocation) => {
            const center = {
              lat: u.latitude,
              lng: u.longitude,
            }
            return (
              <>
                <Marker position={center} onClick={() => this.onLocationClick(u)} icon={new PCIcon()} />
                <Circle center={center} radius={u.accuracy} />
              </>
            )
          })}
      </>
    )
  }

  private onLocationClick(u: IUserLocation) {
    if (this.props.auth.currentUser.email === u.email) {
      this.props.setSidePanelTitle('Your Current Location')
    } else {
      this.props.setSidePanelTitle(`User's ${u.name} Current Location`)
    }
    this.props.setSidePanelContent({
      Latitude: this.positionService.formatCoords(u.latitude, 'lat', this.props.preferenceCoordsDisplayFormat),
      Longitude: this.positionService.formatCoords(u.longitude, 'lon', this.props.preferenceCoordsDisplayFormat),
      Accuracy: u.accuracy + 'm',
      Timestamp: DateService.timestampMsToReadableDate(u.timestamp),
    })
    this.props.setSidePanelVisibility(true)
    // Move sidepanel if sidebar is open
    this.props.sidePanelVerification()
    this.props.setEditVehicle(undefined)
  }

  private displayMyLocationInfo(myLoc: any) {
    if (this.props.auth.currentUser.email) {
      this.props.setSidePanelTitle(`User's ${this.props.auth.currentUser.name} Location`)
    }
    this.props.setSidePanelContent({
      Latitude: this.positionService.formatCoords(myLoc.lat, 'lat', this.props.preferenceCoordsDisplayFormat),
      Longitude: this.positionService.formatCoords(myLoc.lng, 'lon', this.props.preferenceCoordsDisplayFormat),
    })
    this.props.setSidePanelVisibility(true)
    // Move sidepanel if sidebar is open
    this.props.sidePanelVerification()
    this.props.setEditVehicle(undefined)
  }

  private buildAnnotations() {
    return this.props.annotations.map((a: IAnnotation) => {
      return (
        <Marker
          key={'annotation_' + a.id}
          position={{ lat: a.latitude, lng: a.longitude }}
          onClick={() => {
            this.props.setSidePanelTitle(`Annotation ${a.id}`)
            this.props.setSidePanelContent({
              user: a.username,
              date: DateService.timestampMsToReadableDate(a.date),
              content: a.content,
            })
            this.props.setSidePanelVisibility(true)
            this.props.sidePanelVerification()
            this.props.setEditVehicle(undefined)
          }}
        />
      )
    })
  }

  private buildMeasureTrack() {
    const positions = this.props.measurePath.map((p) => {
      return { lat: p.latitude, lng: p.longitude }
    })
    const markers = positions.map((location, i) => (
      <Marker
        key={'marker_' + i}
        alt={'measure_' + i}
        title={'Press CTRL and click to delete point'}
        icon={this.blueCircleIcon}
        position={location}
      />
    ))
    return (
      <>
        <Polyline positions={positions} />
        {markers}
      </>
    )
  }

  private onMapMeasureClick(clickLocation: ILatLng) {
    this.props.addMeasurePoint(clickLocation)
    const distance = this.positionService.measureTotalDistance(this.props.measurePath)
    if (this.props.measurePath.length > 1) {
      const angle = this.positionService.getHeadingFromTwoPoints(
        this.props.measurePath[this.props.measurePath.length - 2],
        this.props.measurePath[this.props.measurePath.length - 1]
      )
      this.props.setSidePanelContent({
        length: `${distance} m (` + this.positionService.metersToNauticalMiles(distance) + ' nm)',
        angle: `${angle.toFixed(2)} º`,
      })
    } else {
      this.props.setSidePanelContent({
        length: `${distance} m`,
      })
    }
    this.props.setEditVehicle(undefined)
  }

  private onMapMeasureClickRemove(index: number) {
    this.props.removeMeasurePoint(index)
    const distance = this.positionService.measureTotalDistance(this.props.measurePath)
    if (this.props.measurePath.length > 1) {
      const angle = this.positionService.getHeadingFromTwoPoints(
        this.props.measurePath[this.props.measurePath.length - 2],
        this.props.measurePath[this.props.measurePath.length - 1]
      )
      this.props.setSidePanelContent({
        length: `${distance} m (` + this.positionService.metersToNauticalMiles(distance) + ' nm)',
        angle: `${angle.toFixed(2)} º`,
      })
    } else {
      this.props.setSidePanelContent({
        length: `${distance} m`,
      })
    }
    this.props.setEditVehicle(undefined)
  }

  private onMapAddClick(clickLocation: ILatLng) {
    this.props.setSidePanelVisibility(false)
    this.setAssetSelected(undefined)
    if (this.props.selectedPlan.id.length === 0) {
      return
    }
    this.props.addWpToPlan(Object.assign({}, clickLocation, { timestamp: 0 }))
  }

  private onMapMoveClick(clickLocation: ILatLng) {
    this.props.setSidePanelVisibility(false)
    this.setAssetSelected(undefined)
    if (this.props.selectedPlan.id.length === 0) {
      return
    }
    if (this.props.selectedWaypointIdx !== -1) {
      this.props.updateWpLocation(clickLocation)
      this.props.setSelectedWaypointIdx(-1)
    }
  }

  private buildNewAnnotationMarker() {
    if (this.props.toolSelected === ToolSelected.ANNOTATION) {
      const location = this.props.toolClickLocation
      if (location == null) {
        return <></>
      }
      return (
        <Marker position={{ lat: location.latitude, lng: location.longitude }}>
          <Popup
            ref={this.newAnnotationPopupRef}
            onClose={() => {
              this.props.setToolClickLocation(null)
            }}
          >
            <textarea
              name="content"
              placeholder="Add your note"
              onChange={(evt) => {
                this.setState({ newAnnotationContent: evt.target.value })
              }}
              value={this.state.newAnnotationContent}
            />
            <Button
              onClick={async () => {
                if (this.newAnnotationPopupRef.current != null) {
                  this.newAnnotationPopupRef.current.onClose()
                }
                try {
                  await this.logBookService.addAnnotation(new NewAnnotation(this.state.newAnnotationContent, location))
                } catch (e) {
                  NotificationManager.error('Please create a logbook first')
                }

                this.setState({ newAnnotationContent: '' })
              }}
            >
              Submit
            </Button>
          </Popup>
        </Marker>
      )
    }
  }

  /**
   * When a click is detected on the map and the Annotation tool is selected
   * @param location The click location
   */
  private onMapAnnotationClick(location: ILatLng) {
    this.props.setToolClickLocation(location)
  }

  private buildEditVehicleModal() {
    if (!this.props.editVehicle) {
      return
    }
    return (
      <Modal
        id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'}
        className="vehicle-modal"
        isOpen={this.props.isVehicleModalOpen}
        toggle={this.props.onSettingsClick}
      >
        <ModalHeader toggle={this.props.onSettingsClick}>Edit vehicle settings</ModalHeader>
        <ModalBody>
          <ul>
            {this.props.editVehicle.settings.map((param: string[]) => {
              const key: string = param[0]
              const value: string = param[1]
              return (
                <li key={key}>
                  <Label for={key}>{key}</Label>
                  <Input type="text" value={value} onChange={(evt: any) => this.updateVehicleParam(evt, key)} />
                </li>
              )
            })}
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onEditVehicle}>
            Save changes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  private updateVehicleParam(evt: any, key: string) {
    if (!this.props.editVehicle) {
      return
    }
    const newValue = evt.target.value
    const editVehicle = JSON.parse(JSON.stringify(this.props.editVehicle))
    const index = editVehicle.settings.findIndex((param: string[]) => param[0] === key)
    editVehicle.settings[index][1] = newValue
    this.props.setEditVehicle(editVehicle)
    this.vehicleChangedSettings.set(key, newValue)
  }

  private async onEditVehicle() {
    const index = this.props.vehicles.findIndex(
      (v: IAsset) => this.props.editVehicle && isSameAsset(v, this.props.editVehicle)
    )
    if (index === -1 || !this.props.editVehicle) {
      return
    }
    try {
      const response = await this.soiService.updateSoiSettings(
        this.props.editVehicle.imcid,
        this.vehicleChangedSettings
      )
      const vehicleCopy = JSON.parse(JSON.stringify(this.props.vehicles[index]))
      vehicleCopy.settings = [...this.props.editVehicle.settings]
      this.props.updateVehicle(vehicleCopy)
      NotificationManager.success(response.message)
    } catch (error) {
      NotificationManager.error(error.message)
    }
  }

  private setAssetSelected(system: IAsset | undefined) {
    this.setState({ assetSelected: system })
  }

  private buildRipplesTools() {
    return (
      <div className={this.props.auth.authenticated ? 'ripples-tools' : 'ripples-tools-nologin'}>
        <div className="tool-ruler">
          <i
            onClick={async () => {
              await this.onMeasureToggle()
              this.props.sidePanelVerification()
            }}
            className={
              'fas fa-ruler-horizontal fa-lg ' + (this.props.toolSelected === ToolSelected.MEASURE ? 'selected' : '')
            }
            title="Measure Tool"
          />
        </div>

        {this.props.auth.authenticated && !isL4(this.props.auth) && (
          <div className="tool-annotation">
            <i
              onClick={this.onAnnotationToggle}
              className={
                'far fa-sticky-note fa-lg ' + (this.props.toolSelected === ToolSelected.ANNOTATION ? 'selected' : '')
              }
              title="Annotation Tool"
            />
          </div>
        )}

        {this.props.auth.authenticated && !isL4(this.props.auth) && (
          <div className="tool-weather">{this.buildWeatherSelector()}</div>
        )}

        <div className="tool-gps">
          <i
            onClick={this.onGpsClick}
            className={'fas fa-map-marker-alt fa-lg ' + (this.props.isGpsActive ? 'selected' : '')}
            title="Enable Gps Tracking"
          />
        </div>

        {this.props.auth.authenticated && !isL4(this.props.auth) && (
          <div className="tool-mylocation">
            <i
              onClick={this.onMyLocationToggle}
              className={'fas fa-location-arrow fa-lg ' + (this.state.isMyLocationActive ? 'selected' : '')}
              title="My location"
            />
          </div>
        )}
      </div>
    )
  }

  private async onMapToolpickClick(clickLocation: ILatLng) {
    this.props.setToolClickLocation(clickLocation)
    try {
      if (!this.props.weatherParam) {
        return
      }
      const response = await MapUtils.fetchWeatherData(clickLocation, this.props.weatherParam)
      if (response === null) {
        NotificationManager.warning('Cannot fetch weather data')
      }
      this.setState({ clickLocationWeather: response })
    } catch (error) {
      NotificationManager.error('Fail to fetch data')
    }
  }

  private buildToolpickMarker() {
    if (this.props.toolSelected === ToolSelected.TOOLPICK) {
      const location = this.props.toolClickLocation
      const weather: IWeather[] = this.state.clickLocationWeather
      if (!(location && weather.length > 0 && this.props.weatherParam)) {
        return
      }
      const sourcesData: WeatherSource = this.weatherService.joinSourceValues(weather, this.props.weatherParam)
      const graphData: WeatherData[] = this.weatherService.preparePlotData(sourcesData)
      return (
        <Marker position={this.positionService.getLatLng(location)}>
          <Popup minWidth={300} maxWidth={600}>
            <WeatherLinePlot param={this.props.weatherParam} data={graphData} />
          </Popup>
        </Marker>
      )
    }
  }
}

function mapStateToProps(state: IRipplesState) {
  return {
    auth: state.auth,
    aisLocations: state.assets.aisDrawableLocations,
    aisShips: state.assets.aisShips,
    aircrafts: state.assets.aircrafts,
    plans: state.planSet,
    profiles: state.profiles,
    selectedPlan: state.selectedPlan,
    selectedWaypointIdx: state.selectedWaypointIdx,
    spots: state.assets.spots,
    ccus: state.assets.ccus,
    toolSelected: state.toolSelected,
    isGpsActive: state.isGpsActive,
    vehicles: state.assets.vehicles,
    measurePath: state.measurePath,
    annotations: state.annotations,
    usersLocations: state.usersLocations,
    isVehicleModalOpen: state.isVehicleModalOpen,
    editVehicle: state.editVehicle,
    sliderValue: state.sliderValue,
    hasSliderChanged: state.hasSliderChanged,
    weatherParam: state.weatherParam,
    toolClickLocation: state.toolClickLocation,
    geoLayers: state.geoLayers,
    pollution: state.pollution,
    obstacle: state.obstacle,
    pollutionSample: state.pollutionSample,
    contacts: state.contacts,
    vehicleSelectedLastState: state.vehicleSelectedLastState,
    vehicleSelected: state.vehicleSelected,
    planSelectedPosition: state.planSelectedPosition,
    contactSelected: state.contactSelected,
    mapElements: state.mapElements,
    isDarkMode: state.isDarkMode,
    servicesAvailable: state.servicesAvailable,
  }
}

const actionCreators = {
  addWpToPlan,
  selectVehicleLastState,
  selectPlanPosition,
  setSelectedWaypointIdx,
  setCcus,
  setVehicles,
  setSpots,
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  sidePanelVerification,
  setToolSelected,
  updateWpLocation,
  addMeasurePoint,
  removeMeasurePoint,
  clearMeasure,
  toggleVehicleModal,
  setEditVehicle,
  setMapOverlayInfo,
  setToolClickLocation,
  updateVehicle,
  toggleSliderChange,
  toggleGps,
  setWeatherParam,
  selectContact,
  selectMapElement,
  setEditingMapElement,
}

export default connect(mapStateToProps, actionCreators)(RipplesMap)
