import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Col,
  Collapse,
  Container,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  Navbar,
  NavbarToggler,
  Row,
  Table,
} from 'reactstrap'
import IAuthState, { isL0, isL1, isL4, IUser } from '../../model/IAuthState'
import IRipplesState from '../../model/IRipplesState'
import { setUser } from '../../redux/ripples.actions'
import { fetchUsers, getCurrentUser } from '../../services/UserUtils'
import { createDomain, deleteDomain, fetchDomainNames, updateDomain } from '../../services/DomainUtils'
import TopNavLinks from '../../components/TopNavLinks'
import Login from '../../components/Login'
import { Link } from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import ZerotierService from '../../services/ZerotierUtils'
import { createApiKey, fetchApiKeys, removeApiKey } from '../../services/ApiKeyUtils'
import DateService from '../../services/DateUtils'
import 'react-datepicker/dist/react-datepicker.css'
import SoiService from '../../services/SoiUtils'
import LocalStorageService from '../../services/LocalStorageService'
import { IServicesDefinition } from '../../model/IService'
import { isChatServiceAvailable, isMyMapsServiceAvailable } from '../../services/ServicesUtils'
import ICampaign from '../../model/ICampaign'
import { fetchCampaignNames } from '../../services/CampaignUtils'
const { NotificationManager } = require('react-notifications')

interface StateType {
  isNavOpen: boolean
  settingInputElem: any | null
  settingInputValue: string
  isSettingModalOpen: boolean
  isNewSettingModalOpen: boolean
  isParamConfirmModalOpen: boolean
  isConfirmModalOpen: boolean
  settingId: string
  settingDomainName: string
  settingParamName: string
  settingNewInputTitle: string
  settingNewInputValue: string
  settingNewInputDomain: string
  domains: string[]
  campaignsAvailable: string[]
  nodeId: string
  isZtModalOpen: boolean
  isZtSelectorOpen: boolean
  ztCmd: string
  isChatModalOpen: boolean
  isTokenModalOpen: boolean
  isApiKeyModalOpen: boolean
  isDomainModalOpen: boolean
  isRemoveDomainModalOpen: boolean
  isPlanModalOpen: boolean
  isRemovePlanModalOpen: boolean
  plansID: string[]
  planToDelete: string
  domainToRemove: any | null
  isNewTokenVisible: boolean
  isNewApiKeyVisible: boolean
  isRemoveTokenModalOpen: boolean
  isRemoveApiKeyModalOpen: boolean
  newApiKeyCampaignSelected: string
  apiKeys: IApiKeys[]
  isNewTokenModalOpen: boolean
  isNewApiKeyModalOpen: boolean
  isPreferencesModalOpen: boolean
  tokenGenerated: string
  tokenToRemove: string
  tokenEmailInput: string
  permissions: string[]
  domainInputElem: any | null
  domainInputValue: string
  domainPreviousValue: string
  domainNewInput: string
  domainNewInputVisible: boolean
  preferenceSymbol: string
  preferenceMeasurementUnit: string
  preferenceCoordinatesFormat: string
  users: {
    name: string
    email: string
  }[]
}

interface PropsType {
  setUser: (user: IUser) => any
  auth: IAuthState
  isDarkMode: boolean
  servicesAvailable: IServicesDefinition[]
  campaignSelected: ICampaign | null
}

interface IApiKeys {
  token: string
  email: string
  domain: string[]
  permission: string[]
  expirationDate: number
}

export class SettingsPanel extends Component<PropsType, StateType> {
  public notificationSystem: any = null
  public timerID: number = 0
  private ztService: ZerotierService = new ZerotierService()
  private soiService: SoiService = new SoiService()
  private localStorageService: LocalStorageService = new LocalStorageService()

  constructor(props: any) {
    super(props)
    this.state = {
      isNavOpen: false,
      settingInputElem: null,
      settingInputValue: '',
      isSettingModalOpen: false,
      isNewSettingModalOpen: false,
      isConfirmModalOpen: false,
      isParamConfirmModalOpen: false,
      settingId: '',
      settingDomainName: '',
      settingParamName: '',
      settingNewInputTitle: '',
      settingNewInputValue: '',
      settingNewInputDomain: '',
      domains: [],
      campaignsAvailable: [],
      nodeId: '',
      isZtModalOpen: false,
      isZtSelectorOpen: false,
      ztCmd: '',
      isTokenModalOpen: false,
      isApiKeyModalOpen: false,
      isDomainModalOpen: false,
      isChatModalOpen: false,
      isRemoveDomainModalOpen: false,
      isPlanModalOpen: false,
      isRemovePlanModalOpen: false,
      plansID: [],
      planToDelete: '',
      domainToRemove: null,
      isNewTokenVisible: false,
      isNewApiKeyVisible: false,
      newApiKeyCampaignSelected: '',
      apiKeys: [],
      isNewTokenModalOpen: false,
      isNewApiKeyModalOpen: false,
      isRemoveTokenModalOpen: false,
      isRemoveApiKeyModalOpen: false,
      isPreferencesModalOpen: false,
      tokenGenerated: '',
      tokenToRemove: '',
      tokenEmailInput: '',
      permissions: ['read', 'write'],
      domainInputElem: null,
      domainInputValue: '',
      domainPreviousValue: '',
      domainNewInput: '',
      domainNewInputVisible: false,
      preferenceSymbol: 'normal',
      preferenceMeasurementUnit: 'metric',
      preferenceCoordinatesFormat: 'DD',
      users: [],
    }
    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this)
    this.fetchApiKeys = this.fetchApiKeys.bind(this)
    this.toogleTokenModal = this.toogleTokenModal.bind(this)
    this.toogleApiKeyModal = this.toogleApiKeyModal.bind(this)
    this.toogleNewTokenModal = this.toogleNewTokenModal.bind(this)
    this.toogleNewApiKeyModal = this.toogleNewApiKeyModal.bind(this)
    this.generateToken = this.generateToken.bind(this)
    this.generateApiKey = this.generateApiKey.bind(this)
    this.removeToken = this.removeToken.bind(this)
    this.toggleZtModal = this.toggleZtModal.bind(this)
    this.toggleZtSelector = this.toggleZtSelector.bind(this)
    this.onNodeIdSubmission = this.onNodeIdSubmission.bind(this)
    this.redirectToChatPage = this.redirectToChatPage.bind(this)
    this.redirectToUserProfilePage = this.redirectToUserProfilePage.bind(this)
    this.toggleChatModal = this.toggleChatModal.bind(this)
    this.toggleDomainModal = this.toggleDomainModal.bind(this)
    this.togglePlanModal = this.togglePlanModal.bind(this)
    this.toogleRemovePlanModal = this.toogleRemovePlanModal.bind(this)
    this.tooglePreferencesModal = this.tooglePreferencesModal.bind(this)
    this.handleChangePreferences = this.handleChangePreferences.bind(this)
    this.handleChangePlan = this.handleChangePlan.bind(this)
    this.removePlan = this.removePlan.bind(this)
  }

  public async loadCurrentlyLoggedInUser() {
    try {
      const user: IUser = await getCurrentUser()
      this.props.setUser(user)
    } catch (error) {
      localStorage.removeItem('ACCESS_TOKEN')
    }
  }

  private async getDomains() {
    const domains: string[] = await fetchDomainNames()

    this.setState({
      domains,
    })
  }

  private async getCampaignsAvailable() {
    const campaignsAvailable: string[] = await fetchCampaignNames(this.props.auth.currentUser.email)
    this.setState({ campaignsAvailable })
  }

  private async getPlansID() {
    const plansID: string[] = await this.soiService.listPlans()
    this.setState({ plansID })
  }

  private fetchLocalStorage() {
    this.setState({
      preferenceSymbol: this.localStorageService.getSymbolsType(),
      preferenceMeasurementUnit: this.localStorageService.getMeasurementUnit(),
      preferenceCoordinatesFormat: this.localStorageService.getCoordinatesFormat(),
    })
  }

  private async getUsers() {
    fetchUsers(this.props.auth.currentUser.role)
      .then((data) => {
        const users = data.map((m: any) =>
          Object.assign({}, m, {
            name: m.name,
            email: m.email,
          })
        )
        this.setState({ users })
      })
      .catch((_) => {
        NotificationManager.warning('Failed to fetch users')
      })
  }

  public async componentDidMount() {
    await this.loadCurrentlyLoggedInUser()
    if (!(this.props.auth.authenticated && (isL0(this.props.auth) || isL1(this.props.auth)))) {
      // NotificationManager.error('Only available for administrators')
    } else {
      this.getDomains()
      this.getCampaignsAvailable()
      this.getPlansID()
    }
    if (this.props.auth.authenticated && !isL4(this.props.auth)) {
      this.getDomains()
      this.getCampaignsAvailable()
      this.fetchApiKeys()
    }
    if (this.props.auth.authenticated && (isL0(this.props.auth) || isL1(this.props.auth))) {
      this.getUsers()
    }

    // check LocalStorage
    this.fetchLocalStorage()
  }

  public componentWillUnmount() {
    clearInterval(this.timerID)
  }

  public onNavToggle() {
    this.setState({ isNavOpen: !this.state.isNavOpen })
  }

  public render() {
    return (
      <>
        <Navbar id={this.props.isDarkMode ? 'navbar-darkmode' : ''} color="faded" light={true} expand="md">
          <NavbarToggler className="mr-2" onClick={() => this.setState({ isNavOpen: !this.state.isNavOpen })} />
          <Collapse isOpen={this.state.isNavOpen} navbar={true}>
            <TopNavLinks />
            <Nav className="ml-auto" navbar={true}>
              {this.props.auth.authenticated && this.buildUserProfilePage()}
              {/*this.props.auth.authenticated && !isCasual(this.props.auth) && this.buildTokenSelector()*/}
              {this.props.auth.authenticated && !isL4(this.props.auth) && this.buildZerotierSelector()}

              <Login />
            </Nav>
          </Collapse>
        </Navbar>

        <div className={this.props.isDarkMode ? 'settings-panel-content darkmode' : 'settings-panel-content'}>
          <Container fluid={true}>
            {this.props.auth.authenticated && !isL4(this.props.auth) && (
              <>
                <Row className="justify-content-center">
                  <Col className="setting-col">{this.redirectToSoiRiskAnalysisPage()}</Col>
                  <Col className="setting-col">{this.redirectToTextMessagesPage()}</Col>
                  {isChatServiceAvailable(this.props.servicesAvailable) && (
                    <Col className="setting-col">{this.buildChatSelector()}</Col>
                  )}
                  {isMyMapsServiceAvailable(this.props.servicesAvailable) && (
                    <Col className="setting-col">{this.redirectToKmlManagerPage()}</Col>
                  )}
                </Row>

                <Row className="justify-content-center">
                  <Col className="setting-col">{this.buildTokenSelector()}</Col>
                  <Col className="setting-col">{this.buildApiKeySelector()}</Col>
                  <Col className="setting-col">{this.buildPreferences()}</Col>
                  <Col className="setting-col">{this.buildZerotier()}</Col>
                  <Col className="setting-col">{this.redirectToCampaignPage()}</Col>
                </Row>
              </>
            )}

            {this.props.auth.authenticated && (isL0(this.props.auth) || isL1(this.props.auth)) && (
              <Row className="justify-content-center">
                <Col className="setting-col">{this.redirectToUsersManagerPage()}</Col>
                <Col className="setting-col">{this.buildDomainEditor()}</Col>
                <Col className="setting-col">{this.buildPlanManager()}</Col>
                <Col className="setting-col">{this.redirectToServicesPage()}</Col>
                <Col className="setting-col">{this.redirectToSettingsPage()}</Col>
              </Row>
            )}
          </Container>
        </div>
      </>
    )
  }

  public buildZerotier() {
    return (
      <>
        <i
          className={'fas fa-network-wired fa-4x '}
          title="Join Ripples Zerotier Network"
          onClick={this.toggleZtSelector}
        />
        <p className="settings-panel-info-domain">Zerotier Network</p>
        {this.buildZerotierSelector()}
        {this.buildZerotierModal()}
      </>
    )
  }

  public buildZerotierSelector() {
    return (
      <Modal
        id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'}
        isOpen={this.state.isZtSelectorOpen}
        toggle={this.toggleZtSelector}
      >
        <ModalHeader toggle={this.toggleZtSelector}> Zerotier Network </ModalHeader>
        <ModalBody>
          <InputGroup>
            <Input
              name="node_address"
              placeholder="Node address"
              onChange={(evt) => this.setState({ nodeId: evt.target.value })}
              value={this.state.nodeId}
              type="text"
              required={true}
            />
          </InputGroup>
          <div className="btn-zt-modal">
            <Button color="primary" onClick={this.onNodeIdSubmission}>
              Add node
            </Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  /*
    public buildZerotierSelector() {
        return (
            <>
                <UncontrolledDropdown id="tooltip-zt" nav={true} className="mr-4 active">
                    <DropdownToggle nav={true} caret={false}>
                        <i className={'fas fa-network-wired fa-lg'} title="Join Ripples Zerotier Network" />
                    </DropdownToggle>
                    <DropdownMenu right={true} className={'zt-dialog'}>
                        <InputGroup>
                            <Input
                                name="node_address"
                                placeholder="Node address"
                                onChange={(evt) => this.setState({ nodeId: evt.target.value })}
                                value={this.state.nodeId}
                                type="text"
                                required={true}
                            />
                        </InputGroup>
                        <Button onClick={this.onNodeIdSubmission}>Add node</Button>
                    </DropdownMenu>
                </UncontrolledDropdown>
                {this.buildZerotierModal()}
            </>
        )
    }
    */

  public buildZerotierModal() {
    const { ztCmd } = this.state
    return (
      <Modal
        id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'}
        key={'zt_modal'}
        isOpen={this.state.isZtModalOpen}
        toggle={this.toggleZtModal}
      >
        <ModalHeader toggle={this.toggleZtModal}>Connect to the Ripples ZeroTier network</ModalHeader>
        <ModalBody>
          <code>$ {ztCmd}</code>
        </ModalBody>
        <ModalFooter>
          <CopyToClipboard text={ztCmd} onCopy={() => this.onCmdCopy()}>
            <Button color="primary">Copy command</Button>
          </CopyToClipboard>
          <Button color="secondary" onClick={this.toggleZtModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  public async onNodeIdSubmission() {
    const { nodeId } = this.state
    if (nodeId === '' || nodeId.length !== 10) {
      NotificationManager.error('Please insert a valid 10-digit ZeroTier node ID!')
      return
    }
    const { status, message } = await this.ztService.joinNetwork(nodeId)
    if (status === 'Success') {
      NotificationManager.success('Node added successfully to the Ripples Zerotier network!')
      this.setState({ isZtModalOpen: true, ztCmd: message, isZtSelectorOpen: !this.state.isZtSelectorOpen })
    } else {
      NotificationManager.error(message)
      this.setState({ isZtModalOpen: false })
    }
    this.setState({ nodeId: '' })
  }

  public toggleZtModal() {
    this.setState({ isZtModalOpen: !this.state.isZtModalOpen })
  }

  public toggleZtSelector() {
    this.setState({ isZtSelectorOpen: !this.state.isZtSelectorOpen })
  }

  public onCmdCopy() {
    NotificationManager.success('Command copied to clipboard!')
    this.toggleZtModal()
  }

  public async fetchApiKeys() {
    let email = this.props.auth.currentUser.email
    if (isL0(this.props.auth) || isL1(this.props.auth)) {
      email = 'all'
    }
    const apiKeys: any = await fetchApiKeys(email)
    this.setState({ apiKeys })
  }

  public buildTokenSelector() {
    return (
      <>
        <i className={'fas fa-key fa-4x'} title="Generate API key" onClick={this.toogleTokenModal} />
        <p className="settings-panel-info-key">Generate API key</p>
        {this.buildTokenModal()}
        {this.buildNewTokenModal()}
        {this.buildRemoveTokenModal()}
      </>
    )
  }

  public buildApiKeySelector() {
    return (
      <>
        <i className={'fas fa-key fa-4x'} title="Campaign - API key" onClick={() => this.toogleApiKeyModal()} />
        <p className="settings-panel-info-key">Campaign - API key</p>
        {this.buildApiKeyModal()}
        {this.buildNewApiKeyModal()}
        {this.buildRemoveApiKeyModal()}
      </>
    )
  }

  public buildDomainEditor() {
    return (
      <>
        <i className={'fas fa-user-cog fa-4x '} title="Edit Domains" onClick={this.toggleDomainModal} />
        <p className="settings-panel-info-domain">Edit Domains</p>
        {this.buildDomainModal()}
        {this.buildRemoveDomainModal()}
      </>
    )
  }

  private buildChatSelector() {
    return (
      <>
        <i className={'fas fa-comments fa-4x'} title="Chat Messages" onClick={this.toggleChatModal} />
        <p className="settings-panel-info-domain">Chat</p>
        {this.buildChatModal()}

        <Link id="chat-link" to="/chat" />
      </>
    )
  }

  public buildPlanManager() {
    return (
      <>
        <i className={'fas fa-ruler-combined fa-4x'} onClick={this.togglePlanModal} />
        <p className="settings-panel-info-domain">Plan Manager</p>
        {this.buildPlanManagerModal()}
        {this.buildRemovePlanModal()}
      </>
    )
  }

  public buildPreferences() {
    return (
      <>
        <i className="fas fa-list fa-4x" title="Preferences" onClick={this.tooglePreferencesModal} />
        <p className="settings-panel-info-key">Preferences</p>
        {this.buildPreferencesModal()}
      </>
    )
  }

  public buildDomainModal() {
    return (
      <Modal
        id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'}
        isOpen={this.state.isDomainModalOpen}
        toggle={this.toggleDomainModal}
      >
        <ModalHeader toggle={this.toggleDomainModal}> Edit domain </ModalHeader>
        <ModalBody>
          {this.state.domains.map((d, index) => {
            return this.state.domainInputElem && this.state.domainInputElem.id === 'domain-' + index ? (
              <div key={index} className="domainRow" domain-input={'domain-' + index}>
                <input
                  type="text"
                  className="domain-input"
                  id={'domain-' + index}
                  value={this.state.domainInputValue}
                  onChange={(event) => this.setState({ domainInputValue: event.target.value })}
                  disabled={false}
                />
                <i className="fas fa-check" title="Update domain" onClick={() => this.updateDomainName()} />
              </div>
            ) : (
              <div key={index} className="domainRow" domain-input={'domain-' + index}>
                <input type="text" className="domain-input" id={'domain-' + index} value={d} disabled={true} />
                <i
                  className="fas fa-pencil-alt"
                  title="Edit domain"
                  onClick={(event) => this.enableInputDomain(event)}
                />
                <i
                  className="fas fa-trash"
                  title="Remove domain"
                  onClick={(event) => this.toogleRemoveDomainModal(event)}
                />
              </div>
            )
          })}
          {this.state.domainNewInputVisible ? (
            <div>
              <input
                type="text"
                className="domain-input"
                id={'domain-new-input'}
                placeholder="Domain name"
                value={this.state.domainNewInput}
                onChange={(event) => this.setState({ domainNewInput: event.target.value })}
              />
              <div className="btn-domain-modal">
                <Button color="success" onClick={() => this.createDomain()}>
                  Add
                </Button>
                <Button color="secondary" onClick={() => this.setState({ domainNewInputVisible: false })}>
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <div className="btn-domain-modal">
              <Button
                color="primary"
                onClick={() => this.setState({ domainNewInputVisible: !this.state.domainNewInputVisible })}
              >
                New domain
              </Button>
            </div>
          )}
        </ModalBody>
      </Modal>
    )
  }

  public buildRemoveDomainModal() {
    return (
      <Modal id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'} isOpen={this.state.isRemoveDomainModalOpen}>
        <ModalHeader toggle={() => this.toogleRemoveDomainModal(null)}> Remove domain </ModalHeader>
        <ModalBody>
          <div> The domain will be removed. Do you want to continue?</div>
          <Button color="danger" onClick={() => this.removeDomain()}>
            Yes
          </Button>
        </ModalBody>
      </Modal>
    )
  }

  private buildChatModal() {
    const { campaignSelected } = this.props

    return (
      <Modal isOpen={this.state.isChatModalOpen} toggle={this.toggleChatModal}>
        <ModalHeader toggle={this.toggleChatModal}> Choose mission </ModalHeader>
        <ModalBody>
          {campaignSelected && (
            <>
              <div key="chatGroup" className="chatGroup" onClick={() => this.redirectToChatPage(campaignSelected.name)}>
                {campaignSelected.name}
              </div>

              {campaignSelected.missions.map((mission, index) => {
                return (
                  <div
                    key={'chatGroup-' + index}
                    className="chatGroup"
                    onClick={() => this.redirectToChatPage(mission.id)}
                  >
                    {mission.name}
                  </div>
                )
              })}
            </>
          )}
        </ModalBody>
      </Modal>
    )
  }

  public async createDomain() {
    const domainName = this.state.domainNewInput

    const response = await createDomain(domainName)
    if (response.status === 'Success') {
      this.toggleDomainModal()
      this.setState({
        domainNewInput: '',
        domainNewInputVisible: false,
      })
      this.getDomains()
      NotificationManager.success('Created domain')
    } else {
      NotificationManager.warning('Cannot create domain')
    }
  }

  public async removeDomain() {
    const response = await deleteDomain(this.state.domainToRemove.value)
    if (response.status === 'Success') {
      this.getDomains()
      NotificationManager.success('Domain removed')
      this.setState({ isRemoveDomainModalOpen: !this.state.isRemoveDomainModalOpen, domainToRemove: null })
    } else {
      NotificationManager.warning('Cannot remove domain')
    }
  }

  public async updateDomainName() {
    const previousDomainName = this.state.domainPreviousValue
    const newDomainName = this.state.domainInputValue

    const response = await updateDomain(previousDomainName, newDomainName)
    if (response.status === 'Success') {
      this.toggleDomainModal()
      this.setState({
        domainInputElem: null,
        domainInputValue: '',
        domainPreviousValue: '',
      })
      this.getDomains()

      NotificationManager.success('Updated domain name')
    } else {
      NotificationManager.warning('Cannot update domain name')
    }
  }

  public enableInputDomain(event: any) {
    const elem = event.target
    const domainInputId = elem.parentElement.getAttribute('domain-input')
    const inputElem: any = document.getElementById(domainInputId)

    if (inputElem != null) {
      this.setState({
        domainInputElem: inputElem,
        domainInputValue: inputElem.value,
        domainPreviousValue: inputElem.value,
      })
    } else {
      this.setState({
        domainInputElem: null,
        domainInputValue: '',
        domainPreviousValue: '',
      })
    }
  }

  private handleChangePlan(event: any) {
    const elem = event.target
    const value = elem.value
    this.setState({ planToDelete: value })
  }

  private handleChangePreferences(event: any) {
    const elem = event.target
    const key = event.target.id
    const value = elem.value
    if (key === 'input-symbols') {
      this.localStorageService.setSymbolsType(value)
      this.setState({ preferenceSymbol: value })
      NotificationManager.success('Updated parameter')
    } else if (key === 'input-measurementUnit') {
      this.localStorageService.setMeasurementUnit(value)
      this.setState({ preferenceMeasurementUnit: value })
      NotificationManager.success('Updated parameter')
    } else if (key === 'input-coordinatesFormat') {
      this.localStorageService.setCoordinatesFormat(value)
      this.setState({ preferenceCoordinatesFormat: value })
      NotificationManager.success('Updated parameter')
    } else {
      NotificationManager.warning('Unknown parameter')
    }
  }

  public buildTokenModal() {
    return (
      <Modal id={'tokenModal'} isOpen={this.state.isTokenModalOpen} toggle={this.toogleTokenModal}>
        <ModalHeader toggle={this.toogleTokenModal}> API keys </ModalHeader>
        <ModalBody>
          <Table id="token-table" responsive={true} striped={true}>
            <thead>
              <tr>
                <th>Expiration date</th>
                <th>API key</th>
                <th>Domain</th>
                <th>Permission</th>
                <th>User</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {this.state.apiKeys.map((apiKey, index) => {
                return (
                  <tr key={index}>
                    <td>{DateService.timestampToReadableDateOnly(apiKey.expirationDate)}</td>
                    <td>
                      {apiKey.token}
                      <CopyToClipboard text={apiKey.token} onCopy={() => this.onTokenCopy()}>
                        <i className="fas fa-copy fa-lg" />
                      </CopyToClipboard>
                    </td>

                    <td>
                      {this.state.domains.map((d, indexDomain) => {
                        return (
                          <label key={indexDomain}>
                            <input
                              type="checkbox"
                              className={'optDomain-' + indexDomain}
                              value={d}
                              checked={apiKey.domain.includes(d) ? true : false}
                              disabled={true}
                            />
                            {d}
                          </label>
                        )
                      })}
                    </td>

                    <td>
                      {this.state.permissions.map((p, indexPermission) => {
                        return (
                          <label key={indexPermission}>
                            <input
                              type="checkbox"
                              className={'optPermission-' + indexPermission}
                              value={p}
                              checked={apiKey.permission.includes(p) ? true : false}
                              disabled={true}
                            />
                            {p}
                          </label>
                        )
                      })}
                    </td>

                    <td>{apiKey.email}</td>

                    <td>
                      {this.props.auth.authenticated ? (
                        <i
                          className="fas fa-trash"
                          title="Remove API Key"
                          onClick={(event) => this.toogleRemoveModalModal(apiKey.token)}
                        />
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>

          {this.state.isNewTokenVisible ? (
            <>
              <hr />

              <h5 className="token-title">API key settings:</h5>

              <div className="token-email-input">
                <span>Email:</span>
                <select
                  value={this.state.tokenEmailInput}
                  onChange={(event: any) => {
                    this.setState({
                      tokenEmailInput: event.target.value,
                    })
                  }}
                >
                  <option disabled={true} hidden={true} value="" />
                  {this.state.users.map((user, index) => {
                    return (
                      <option value={user.email} key={index} onClick={(event) => event.stopPropagation()}>
                        {user.name + ' [' + user.email + ']'}
                      </option>
                    )
                  })}
                </select>
              </div>

              <div className="token-domain-input">
                <span>Domain:</span>
                {this.state.domains.map((d, indexDomain) => {
                  return (
                    <label key={indexDomain}>
                      <input type="checkbox" className={'new-token-domain'} value={d} />
                      {d}
                    </label>
                  )
                })}
              </div>

              <div className="token-permission-input">
                <span>Permissions:</span>
                {this.state.permissions.map((p, indexPermission) => {
                  return (
                    <label key={indexPermission}>
                      <input type="checkbox" className={'new-token-permission'} value={p} />
                      {p}
                    </label>
                  )
                })}
              </div>

              <Button color="success" className="new-token-btn" onClick={() => this.generateToken()}>
                Generate key
              </Button>
              <Button
                color="secondary"
                className="new-token-btn"
                onClick={() => this.setState({ isNewTokenVisible: !this.state.isNewTokenVisible })}
              >
                Cancel
              </Button>
            </>
          ) : isL0(this.props.auth) || isL1(this.props.auth) ? (
            <Button
              color="secondary"
              onClick={() => this.setState({ isNewTokenVisible: !this.state.isNewTokenVisible })}
            >
              Generate API key
            </Button>
          ) : (
            <></>
          )}
        </ModalBody>
      </Modal>
    )
  }

  public buildApiKeyModal() {
    return (
      <>
        <Modal id={'tokenModal'} isOpen={this.state.isApiKeyModalOpen} toggle={this.toogleApiKeyModal}>
          <ModalHeader toggle={this.toogleApiKeyModal}> Campaign - API keys </ModalHeader>

          <ModalBody>
            <Table id="token-table" responsive={true} striped={true}>
              <thead>
                <tr>
                  <th>Expiration date</th>
                  <th>API key</th>
                  <th>Campaigns</th>
                  <th>Permission</th>
                  <th>User</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {this.state.apiKeys.map((apiKey, index) => {
                  return (
                    <tr key={index}>
                      <td>{DateService.timestampToReadableDateOnly(apiKey.expirationDate)}</td>
                      <td>
                        {apiKey.token}
                        <CopyToClipboard text={apiKey.token} onCopy={() => this.onApiKeyCopy()}>
                          <i className="fas fa-copy fa-lg" />
                        </CopyToClipboard>
                      </td>

                      <td>
                        {apiKey.domain.map((campaingName, indexCampaign) => {
                          return (
                            <ul key={'optCampaing-' + campaingName + '-' + indexCampaign}>
                              <li>{campaingName}</li>
                            </ul>
                          )
                        })}
                      </td>

                      <td>
                        {this.state.permissions.map((p, indexPermission) => {
                          return (
                            <label key={indexPermission}>
                              <input
                                type="checkbox"
                                className={'optPermission-' + indexPermission}
                                value={p}
                                checked={apiKey.permission.includes(p) ? true : false}
                                disabled={true}
                              />
                              {p}
                            </label>
                          )
                        })}
                      </td>

                      <td>{apiKey.email}</td>

                      <td>
                        {this.props.auth.authenticated ? (
                          <i
                            className="fas fa-trash"
                            title="Remove API Key"
                            onClick={(event) => this.toogleRemoveApiKeyModal(apiKey.token)}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>

            {this.state.isNewApiKeyVisible ? (
              <>
                <hr />

                <h5 className="token-title">API key settings:</h5>

                <div className="token-email-input">
                  <span>Email:</span>
                  <select
                    value={this.state.tokenEmailInput}
                    onChange={(event: any) => {
                      this.setState({
                        tokenEmailInput: event.target.value,
                      })
                    }}
                  >
                    <option disabled={true} hidden={true} value="" />
                    {this.state.users.map((user, index) => {
                      return (
                        <option value={user.email} key={index} onClick={(event) => event.stopPropagation()}>
                          {user.name + ' [' + user.email + ']'}
                        </option>
                      )
                    })}
                  </select>
                </div>

                <div className="token-domain-input">
                  <span>Campaings:</span>
                  {this.state.campaignsAvailable.map((campaingName, indexCampaign) => {
                    return (
                      <label key={indexCampaign}>
                        <input
                          type="radio"
                          className={'new-token-domain'}
                          value={campaingName}
                          checked={this.state.newApiKeyCampaignSelected === campaingName}
                          onChange={(event) => this.setState({ newApiKeyCampaignSelected: event.target.value })}
                        />
                        {campaingName}
                      </label>
                    )
                  })}
                </div>

                <div className="token-permission-input">
                  <span>Permissions:</span>
                  {this.state.permissions.map((p, indexPermission) => {
                    return (
                      <label key={indexPermission}>
                        <input type="checkbox" className={'new-token-permission'} value={p} />
                        {p}
                      </label>
                    )
                  })}
                </div>

                <Button color="success" className="new-token-btn" onClick={() => this.generateApiKey()}>
                  Generate key
                </Button>
                <Button
                  color="secondary"
                  className="new-token-btn"
                  onClick={() => this.setState({ isNewApiKeyVisible: !this.state.isNewApiKeyVisible })}
                >
                  Cancel
                </Button>
              </>
            ) : isL0(this.props.auth) || isL1(this.props.auth) ? (
              <Button
                color="secondary"
                onClick={() => this.setState({ isNewApiKeyVisible: !this.state.isNewApiKeyVisible })}
              >
                Generate API key
              </Button>
            ) : (
              <></>
            )}
          </ModalBody>
        </Modal>
      </>
    )
  }

  public buildNewTokenModal() {
    return (
      <Modal
        id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'}
        isOpen={this.state.isNewTokenModalOpen}
        toggle={this.toogleNewTokenModal}
      >
        <ModalHeader toggle={this.toogleNewTokenModal}> Generated API key </ModalHeader>
        <ModalBody>
          {this.state.tokenGenerated}
          <CopyToClipboard text={this.state.tokenGenerated} onCopy={() => this.onNewTokenCopy()}>
            <i className="fas fa-copy fa-lg" />
          </CopyToClipboard>
        </ModalBody>
      </Modal>
    )
  }

  public buildNewApiKeyModal() {
    return (
      <Modal
        id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'}
        isOpen={this.state.isNewApiKeyModalOpen}
        toggle={this.toogleNewApiKeyModal}
      >
        <ModalHeader toggle={this.toogleNewApiKeyModal}> Generated API key </ModalHeader>
        <ModalBody>
          {this.state.tokenGenerated}
          <CopyToClipboard text={this.state.tokenGenerated} onCopy={() => this.onNewApiKeyCopy()}>
            <i className="fas fa-copy fa-lg" />
          </CopyToClipboard>
        </ModalBody>
      </Modal>
    )
  }

  public buildRemoveTokenModal() {
    return (
      <Modal id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'} isOpen={this.state.isRemoveTokenModalOpen}>
        <ModalHeader toggle={() => this.toogleRemoveModalModal('')}> Remove API key </ModalHeader>
        <ModalBody>
          <div> The API key will be removed. Do you want to continue?</div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => this.removeToken()}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  public buildRemoveApiKeyModal() {
    return (
      <Modal id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'} isOpen={this.state.isRemoveApiKeyModalOpen}>
        <ModalHeader toggle={() => this.toogleRemoveApiKeyModal('')}> Remove Campaing API key</ModalHeader>
        <ModalBody>
          <div> The API key will be removed. Do you want to continue?</div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => this.removeToken()}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  public buildPlanManagerModal() {
    return (
      <Modal
        id={this.props.isDarkMode ? 'modal-darkmode planModal' : 'modal-light planModal'}
        isOpen={this.state.isPlanModalOpen}
        toggle={this.togglePlanModal}
      >
        <ModalHeader toggle={this.togglePlanModal}> Plan manager </ModalHeader>
        <ModalBody>
          <select
            className="input-remove-plan"
            title="Select plan to delete"
            value={this.state.planToDelete === '' ? 'Select plan to remove' : this.state.planToDelete}
            onChange={this.handleChangePlan}
            onClick={(event) => event.stopPropagation()}
          >
            <option value={''}>Select plan to remove</option>
            {this.state.plansID.map((plan, index) => {
              return (
                <option value={plan} key={index} onClick={(event) => event.stopPropagation()}>
                  {plan}
                </option>
              )
            })}
          </select>
        </ModalBody>
        {this.state.planToDelete !== '' && (
          <Button color="danger" className="btn-remove-plan" onClick={() => this.toogleRemovePlanModal()}>
            Remove
          </Button>
        )}
      </Modal>
    )
  }

  public buildRemovePlanModal() {
    return (
      <Modal id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'} isOpen={this.state.isRemovePlanModalOpen}>
        <ModalHeader toggle={() => this.toogleRemovePlanModal()}> Remove Plan </ModalHeader>
        <ModalBody>
          <div>
            The Plan '<b>{this.state.planToDelete}</b>' will be removed. <br />
            If the plan is assigned to a vehicle, it will be replaced by 'idle' plan. <br />
            Do you want to continue?
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => this.removePlan()}>
            Remove
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  public buildPreferencesModal() {
    return (
      <Modal
        id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'}
        isOpen={this.state.isPreferencesModalOpen}
        toggle={this.tooglePreferencesModal}
      >
        <ModalHeader toggle={this.tooglePreferencesModal}> Local Preferences </ModalHeader>
        <ModalBody>
          <Table
            id="preferences-table"
            responsive={true}
            striped={true}
            className={this.props.isDarkMode ? 'table-darkmode' : ''}
          >
            <tbody>
              {this.buildSymbolPreference()}
              {this.buildMeasurementUnitPreference()}
              {this.buildCoordinatesFormatPreference()}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    )
  }

  private buildSymbolPreference() {
    return (
      <tr>
        <th>Symbols</th>
        <th className="input-preferences">
          <select
            id="input-symbols"
            value={this.state.preferenceSymbol}
            onChange={this.handleChangePreferences}
            onClick={(event) => event.stopPropagation()}
          >
            <option value="normal" onClick={(event) => event.stopPropagation()}>
              Normal
            </option>
            <option value="military" onClick={(event) => event.stopPropagation()}>
              Military
            </option>
          </select>
        </th>
      </tr>
    )
  }

  private buildMeasurementUnitPreference() {
    return (
      <tr>
        <th>Measurement unit</th>
        <th className="input-preferences">
          <select
            id="input-measurementUnit"
            value={this.state.preferenceMeasurementUnit}
            onChange={this.handleChangePreferences}
            onClick={(event) => event.stopPropagation()}
          >
            <option value="metric" onClick={(event) => event.stopPropagation()}>
              Metric
            </option>
            <option value="nautic" onClick={(event) => event.stopPropagation()}>
              Nautic
            </option>
          </select>
        </th>
      </tr>
    )
  }

  private buildCoordinatesFormatPreference() {
    return (
      <tr>
        <th>Lat/Lon display format</th>
        <th className="input-preferences">
          <select
            id="input-coordinatesFormat"
            value={this.state.preferenceCoordinatesFormat}
            onChange={this.handleChangePreferences}
            onClick={(event) => event.stopPropagation()}
          >
            <option value="DD" onClick={(event) => event.stopPropagation()}>
              Decimal Degrees
            </option>
            <option value="DDM" onClick={(event) => event.stopPropagation()}>
              Degrees Decimal Minutes
            </option>
            <option value="DMS" onClick={(event) => event.stopPropagation()}>
              Degrees Minutes Seconds
            </option>
          </select>
        </th>
      </tr>
    )
  }

  public toogleTokenModal() {
    this.setState({ isTokenModalOpen: !this.state.isTokenModalOpen })
  }

  public toogleApiKeyModal() {
    this.setState({ isApiKeyModalOpen: !this.state.isApiKeyModalOpen, isNewApiKeyVisible: false })
  }

  public toggleDomainModal() {
    this.setState({ isDomainModalOpen: !this.state.isDomainModalOpen })
  }

  private toggleChatModal() {
    this.setState({ isChatModalOpen: !this.state.isChatModalOpen })
  }

  private redirectToChatPage(chatName: string) {
    localStorage.setItem('chat-name', chatName)
    const chatLink = document.getElementById('chat-link')
    if (chatLink !== null) {
      chatLink.click()
    }
  }

  public toogleRemoveDomainModal(event: any | null) {
    if (event != null) {
      const elem = event.target
      const domainInputId = elem.parentElement.getAttribute('domain-input')
      const inputElem: any = document.getElementById(domainInputId)
      this.setState({ isRemoveDomainModalOpen: !this.state.isRemoveDomainModalOpen, domainToRemove: inputElem })
    } else {
      this.setState({ isRemoveDomainModalOpen: !this.state.isRemoveDomainModalOpen, domainToRemove: null })
    }
  }

  public toogleNewTokenModal() {
    this.setState({
      isNewTokenModalOpen: !this.state.isNewTokenModalOpen,
      tokenGenerated: '',
    })
  }

  public toogleNewApiKeyModal() {
    this.setState({ isNewApiKeyModalOpen: !this.state.isNewApiKeyModalOpen, tokenGenerated: '' })
  }

  public toogleRemoveModalModal(token: string) {
    this.setState({
      isRemoveTokenModalOpen: !this.state.isRemoveTokenModalOpen,
      tokenToRemove: token,
    })
  }

  public toogleRemoveApiKeyModal(token: string) {
    this.setState({
      isRemoveApiKeyModalOpen: !this.state.isRemoveApiKeyModalOpen,
      tokenToRemove: token,
    })
  }

  public togglePlanModal() {
    this.setState({ isPlanModalOpen: !this.state.isPlanModalOpen, planToDelete: '' })
  }

  public toogleRemovePlanModal() {
    this.setState({ isRemovePlanModalOpen: !this.state.isRemovePlanModalOpen })
  }

  public tooglePreferencesModal() {
    this.setState({
      isPreferencesModalOpen: !this.state.isPreferencesModalOpen,
    })
  }

  public onTokenCopy() {
    NotificationManager.success('API key copied to clipboard!')
    this.toogleTokenModal()
  }

  public onApiKeyCopy() {
    NotificationManager.success('API key copied to clipboard!')
    this.toogleApiKeyModal()
  }

  public onNewTokenCopy() {
    NotificationManager.success('API key copied to clipboard!')
    this.toogleNewTokenModal()
  }

  public onNewApiKeyCopy() {
    NotificationManager.success('API key copied to clipboard!')
    this.toogleNewApiKeyModal()
  }

  public async generateToken() {
    const domains: any = document.getElementsByClassName('new-token-domain')
    const domainSelected: any = []
    for (const domain of domains) {
      if (domain.checked) domainSelected.push(domain.value)
    }

    const permissions: any = document.getElementsByClassName('new-token-permission')
    const permissionSelected: any = []
    for (const permission of permissions) {
      if (permission.checked) permissionSelected.push(permission.value)
    }

    const emailSelected: string = this.state.tokenEmailInput.trim()

    if (domainSelected.length === 0 || permissionSelected.length === 0 || emailSelected.length === 0) {
      NotificationManager.warning('Fields cannot be empty')
    } else {
      // GERAR TOKEN
      const resp = await createApiKey(emailSelected, domainSelected, permissionSelected)
      if (resp.status === 'Success') {
        const respMessage = resp.message.substring(0, resp.message.indexOf(':'))
        const respToken = resp.message.substring(resp.message.indexOf(':') + 1)

        this.setState({
          isNewTokenVisible: !this.state.isNewTokenVisible,
          tokenGenerated: respToken,
          isNewTokenModalOpen: !this.state.isNewTokenModalOpen,
          tokenEmailInput: '',
        })
        NotificationManager.success(respMessage)
        this.fetchApiKeys()
      } else {
        NotificationManager.warning(resp.message)
      }
    }
  }

  public async generateApiKey() {
    const domains: any = document.getElementsByClassName('new-token-domain')
    const domainSelected: any = []
    for (const domain of domains) {
      if (domain.checked) domainSelected.push(domain.value)
    }

    const permissions: any = document.getElementsByClassName('new-token-permission')
    const permissionSelected: any = []
    for (const permission of permissions) {
      if (permission.checked) permissionSelected.push(permission.value)
    }

    const emailSelected: string = this.state.tokenEmailInput.trim()

    if (domainSelected.length === 0 || permissionSelected.length === 0 || emailSelected.length === 0) {
      NotificationManager.warning('Fields cannot be empty')
    } else {
      const resp = await createApiKey(emailSelected, domainSelected, permissionSelected)
      if (resp.status === 'Success') {
        const respMessage = resp.message.substring(0, resp.message.indexOf(':'))
        const respToken = resp.message.substring(resp.message.indexOf(':') + 1)

        this.setState({
          isNewApiKeyVisible: !this.state.isNewApiKeyVisible,
          tokenGenerated: respToken,
          isNewApiKeyModalOpen: !this.state.isNewApiKeyModalOpen,
          tokenEmailInput: '',
        })
        NotificationManager.success(respMessage)
        this.fetchApiKeys()
      } else {
        NotificationManager.warning(resp.message)
      }
    }
  }

  public async removeToken() {
    const resp = await removeApiKey(this.state.tokenToRemove)
    if (resp.status === 'Success') {
      this.setState({
        isRemoveTokenModalOpen: false,
        isRemoveApiKeyModalOpen: false,
        isNewApiKeyVisible: false,
        tokenToRemove: '',
      })
      NotificationManager.success(resp.message)
      this.fetchApiKeys()
    } else {
      NotificationManager.warning(resp.message)
    }
  }

  public async removePlan() {
    const resp = await this.soiService.removePlan(this.state.planToDelete)
    if (resp.status === 'success') {
      this.setState({
        isRemovePlanModalOpen: !this.state.isRemovePlanModalOpen,
        planToDelete: '',
      })
      NotificationManager.success(resp.message)
      this.getPlansID()
    } else {
      NotificationManager.warning(resp.message)
    }
  }

  private redirectToUsersManagerPage() {
    return (
      <>
        <Link className="navbar-link-panel" to="/user/manager">
          <i title="Users Manager" className="fas fa-users fa-4x" />
        </Link>
        <p className="settings-panel-info">Users Manager</p>
      </>
    )
  }

  private redirectToSoiRiskAnalysisPage() {
    return (
      <>
        <Link className="navbar-link-panel" to="/risk">
          <i title="Soi Risk Analysis" className="fas fa-exclamation-triangle fa-4x" />
        </Link>
        <p className="settings-panel-info">Risk Analysis</p>
      </>
    )
  }

  private redirectToTextMessagesPage() {
    return (
      <>
        <Link className="navbar-link-panel" to="/messages/text">
          <i title="Text Messages" className="fas fa-envelope-open-text fa-4x" />
        </Link>
        <p className="settings-panel-info">Text Messages</p>
      </>
    )
  }

  private redirectToKmlManagerPage() {
    return (
      <>
        <Link className="navbar-link-panel" to="/kml/manager">
          <i title="KML Manager" className="fas fa-map fa-4x" />
        </Link>
        <p className="settings-panel-info">KML Manager</p>
      </>
    )
  }

  private redirectToSettingsPage() {
    return (
      <>
        <Link className="navbar-link-panel" to="/settings/manager">
          <i title="Settings Manager" className="fas fa-cogs fa-4x settings-manager-icon" />
        </Link>
        <p className="settings-panel-info">Settings Manager</p>
      </>
    )
  }

  private redirectToServicesPage() {
    return (
      <>
        <Link className="navbar-link-panel" to="/services">
          <i title="Services Manager" className="fas fa-sliders-h fa-4x settings-manager-icon" />
        </Link>
        <p className="settings-panel-info">Services Configuration</p>
      </>
    )
  }

  private buildUserProfilePage() {
    return (
      <>
        <i title="User Profile" className="fas fa-user fa-lg" onClick={this.redirectToUserProfilePage} />
        <Link id="user-link" to="/user/profile" />
      </>
    )
  }

  private redirectToUserProfilePage() {
    localStorage.setItem('user-profile', this.props.auth.currentUser.email)
    const userLink = document.getElementById('user-link')
    if (userLink !== null) {
      userLink.click()
    }
  }

  private redirectToCampaignPage() {
    return (
      <>
        <Link className="navbar-link-panel" to="/campaign">
          <i title="Campaign Manager" className="fas fa-cubes fa-4x" />
        </Link>
        <p className="settings-panel-info">Campaign Manager</p>
      </>
    )
  }
}

function mapStateToProps(state: IRipplesState) {
  return {
    auth: state.auth,
    isDarkMode: state.isDarkMode,
    servicesAvailable: state.servicesAvailable,
    campaignSelected: state.campaignSelected,
  }
}

const actionCreators = {
  setUser,
}

export default connect(mapStateToProps, actionCreators)(SettingsPanel)
