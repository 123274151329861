import React, { Component } from 'react'
import { Marker } from 'react-leaflet'
import IProfile from '../../../model/IProfile'
import PositionService from '../../../services/PositionUtils'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import L from 'leaflet'

interface StateType {
  isProfileModalOpen: boolean
  profileImg: string | undefined
  profileTypes: string[]
  typeSelected: string
  profileTimestamp: number | undefined
}

interface PropsType {
  data: IProfile
}

export default class VerticalProfile extends Component<PropsType, StateType> {
  private positionService: PositionService = new PositionService()

  constructor(props: any) {
    super(props)
    this.state = {
      isProfileModalOpen: false,
      profileImg: '',
      profileTypes: [],
      typeSelected: '',
      profileTimestamp: undefined,
    }

    this.toggleProfileModal = this.toggleProfileModal.bind(this)
    this.fetchProfileData = this.fetchProfileData.bind(this)
    this.updateProfileData = this.updateProfileData.bind(this)
    this.createCustomIcon = this.createCustomIcon.bind(this)
  }

  private fetchProfileData() {
    const date = new Date(this.props.data.timestamp)
    const millis = date.getTime()

    // fetch chart
    const apiChartURL =
      process.env.REACT_APP_API_BASE_URL +
      '/plot/profile/' +
      this.props.data.system +
      '/' +
      this.props.data.type +
      '.png?timestamp=' +
      millis
    fetch(apiChartURL)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob)
        this.setState({
          profileImg: url,
          profileTimestamp: millis,
          typeSelected: this.props.data.type,
          isProfileModalOpen: !this.state.isProfileModalOpen,
        })
      })
      .catch((error) => {
        console.error('Error fetching the image:', error)
      })

    // fetch profile types
    const apiProfileTypesAvailableURL =
      process.env.REACT_APP_API_BASE_URL + '/plot/profileTypes/' + this.props.data.system + '?timestamp=' + millis
    fetch(apiProfileTypesAvailableURL)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ profileTypes: data })
      })
      .catch((error) => {
        console.error('Error fetching the image:', error)
      })
  }

  private updateProfileData(profileType: string) {
    // fetch chart
    const apiChartURL =
      process.env.REACT_APP_API_BASE_URL +
      '/plot/profile/' +
      this.props.data.system +
      '/' +
      profileType +
      '.png?timestamp=' +
      this.state.profileTimestamp
    fetch(apiChartURL)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob)
        this.setState({ profileImg: url, typeSelected: profileType })
      })
      .catch((error) => {
        console.error('Error fetching the image:', error)
      })
  }

  private toggleProfileModal() {
    this.setState({ isProfileModalOpen: !this.state.isProfileModalOpen })
  }

  // Utility function to create a custom icon
  private createCustomIcon() {
    let colorRGB = 'black'
    if (this.props.data.type === 'Temperature') {
      const surfaceTemperature = this.props.data.samples[this.props.data.samples.length - 1][1]
      colorRGB = getColorByTemperature(surfaceTemperature)
    }

    return L.divIcon({
      className: 'custom-temperature-icon',
      html: `<div style="color:${colorRGB}" class="fa fa-times-circle"></div>`,
      iconSize: [5, 5],
      iconAnchor: [5, 5],
    })
  }

  public render() {
    const systemPosition = this.positionService.getLatLng(this.props.data)

    // img url
    const imgUrl =
      process.env.REACT_APP_API_BASE_URL +
      '/plot/profile/' +
      this.props.data.system +
      '/' +
      this.state.typeSelected +
      '.png?timestamp=' +
      this.state.profileTimestamp

    return (
      <Marker position={systemPosition} icon={this.createCustomIcon()} onClick={this.fetchProfileData}>
        <Modal isOpen={this.state.isProfileModalOpen} className="sixty-percent-modal" toggle={this.toggleProfileModal}>
          <ModalHeader toggle={this.toggleProfileModal}>
            {' '}
            {this.props.data.system + ' | ' + this.props.data.timestamp + ' | ' + this.state.typeSelected}{' '}
          </ModalHeader>
          <ModalBody>
            {this.state.profileImg ? (
              <a href={imgUrl}>
                {' '}
                <img src={this.state.profileImg} alt="Fetched from URL" className="img-fluid" />{' '}
              </a>
            ) : (
              'Loading...'
            )}
          </ModalBody>

          <ModalFooter>
            {this.state.profileTypes.map((typeName) => (
              <Button
                key={'profileType-' + typeName}
                color={this.state.typeSelected === typeName ? 'primary' : 'secondary'}
                onClick={() => this.updateProfileData(typeName)}
              >
                {typeName}
              </Button>
            ))}
          </ModalFooter>
        </Modal>
      </Marker>
    )
  }
}

// Convert temperature to RGB color
function getColorByTemperature(temp: number) {
  const clampedTemp = Math.max(0, Math.min(40, temp))
  const hue = (1 - clampedTemp / 40) * 240

  const rgb = hsvToRgb(hue, 1, 1)
  return 'rgb(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ')'
}

// Convert HSV to RGB
function hsvToRgb(h: any, s: any, v: any) {
  let r
  let g
  let b
  const i = Math.floor(h / 60)
  const f = h / 60 - i
  const p = v * (1 - s)
  const q = v * (1 - f * s)
  const t = v * (1 - (1 - f) * s)
  switch (i) {
    case 0:
      r = v
      g = t
      b = p
      break
    case 1:
      r = q
      g = v
      b = p
      break
    case 2:
      r = p
      g = v
      b = t
      break
    case 3:
      r = p
      g = q
      b = v
      break
    case 4:
      r = t
      g = p
      b = v
      break
    case 5:
      r = v
      g = p
      b = q
      break
    default:
      r = 0
      g = 0
      b = 0
  }
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
}
