import IAisShip, { IShipLocation } from './IAisShip'
import IAnnotation from './IAnnotations'
import IAsset from './IAsset'
import IUserState, { IUserLocation } from './IAuthState'
import ILatLng from './ILatLng'
import IOverlayInfo from './IOverlayInfo'
import IPlan from './IPlan'
import IProfile from './IProfile'
import { ToolSelected } from './ToolSelected'
import { WeatherParam } from './WeatherParam'
import IGeoLayer from './IGeoLayer'
import IPollution from './IPollution'
import IObstacle from './IObstacles'
import IAssetState from './IAssetState'
import IPollutionSample from './IPollutionSample'
import IContact from './IContact'
import IMapElement from './IMapElement'
import IAircraft from './IAircraft'
import { IServicesDefinition } from './IService'
import ICampaign from './ICampaign'

export interface IAssetsGroup {
  vehicles: IAsset[]
  spots: IAsset[]
  aisShips: IAisShip[]
  aisDrawableLocations: IShipLocation[]
  ccus: IAsset[]
  aircrafts: IAircraft[]
}

export default interface IRipplesState {
  assets: IAssetsGroup
  selectedPlan: IPlan
  prevSelectedPlan: IPlan | null
  sliderValue: number
  selectedWaypointIdx: number
  auth: IUserState
  profiles: IProfile[]
  planSet: IPlan[]
  previousPlanSet: IPlan[]
  isAnotherSelectedPlan: boolean
  toggledPlan: IPlan | null
  toolSelected: ToolSelected
  isGpsActive: boolean
  vehicleSelected: string
  vehicleSelectedLastState: IAssetState | null
  planSelectedPosition: ILatLng | null
  sidePanelTitle: string
  sidePanelContent: any
  isSidePanelVisible: boolean
  measurePath: ILatLng[]
  annotations: IAnnotation[]
  usersLocations: IUserLocation[]
  isVehicleModalOpen: boolean
  editVehicle?: IAsset
  hasSliderChanged: boolean
  isSliderVisible: boolean
  mapOverlayInfo: IOverlayInfo
  weatherParam: WeatherParam | null
  toolClickLocation: ILatLng | null
  geoLayers: IGeoLayer[] | null
  isEditingPlan: boolean
  updatingPlanId: boolean
  pollution: IPollution[]
  obstacle: IObstacle[]
  pollutionSample: IPollutionSample[]
  contacts: IContact[]
  contactSelected: IContact | null
  mapElements: IMapElement[]
  mapElementSelected: IMapElement | null
  isEditingMapElement: boolean
  isDarkMode: boolean
  servicesAvailable: IServicesDefinition[]
  campaignSelected: ICampaign | null
}

export const defaultAssetsGroup: IAssetsGroup = {
  aisDrawableLocations: [],
  aisShips: [],
  spots: [],
  vehicles: [],
  ccus: [],
  aircrafts: [],
}
