import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Nav from 'reactstrap/lib/Nav'
import logo from '../assets/logo2.png'
import IRipplesState from '../model/IRipplesState'
import { toggleDarkMode } from '../redux/ripples.actions'

interface PropsType {
  isDarkMode: boolean
  toggleDarkMode: () => void
}

/**
 * Should be reused by the other implementations of the navbar
 */
class TopNavLinks extends Component<PropsType, {}> {
  public render() {
    return (
      <Nav id="nav" navbar={true}>
        <Link className="navbar-link" to="/">
          <img id="logo" src={logo} alt="logo" />
        </Link>
        {/* <div>
          <i
            title="Toggle darkmode"
            id="toggle-darkmode"
            className="fas fa-moon"
            onClick={(e) => {
              this.props.toggleDarkMode()
              ;(e.target as HTMLElement).className =
                (e.target as HTMLElement).className === 'fas fa-moon' ? 'fas fa-sun' : 'fas fa-moon'
            }}
          />
        </div> */}
      </Nav>
    )
  }
}

function mapStateToProps(state: IRipplesState) {
  const isDarkMode = state.isDarkMode
  return { isDarkMode }
}

const actionCreators = {
  toggleDarkMode,
}

export default connect(mapStateToProps, actionCreators)(TopNavLinks)
